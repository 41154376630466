import { ToastrService } from "ngx-toastr";
import { SignalRService } from "./../../signal-r.service";
import { SiteSystem } from "../../viewmodels/generated/site-system";
import { JobState } from "../../viewmodels/generated/job-state";
import { ApiEndpoint } from "./../../enum/apiendpoints.enum";
import { SiteSummaryViewModel } from "../../viewmodels/generated/site-summary-view-model";
import { HttpClient, HttpParams, HttpEventType } from "@angular/common/http";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EditSiteAddressComponent } from "../edit-site-address/edit-site-address.component";
import { SiteSystemIsOurs } from "src/app/viewmodels/generated/site-system-is-ours";
import { SiteSystemIsMotorised } from "src/app/viewmodels/generated/site-system-is-motorised";
import { SitePendingImageUpload } from "src/app/viewmodels/site-pending-image-upload";
import { retryWhen, delay } from "rxjs/operators";
import { EditSystemComponent } from "../edit-system/edit-system.component";
import { JobStatus } from "src/app/viewmodels/generated/job-status";
import { UploadState } from "src/app/viewmodels/generated/upload-state";
import { ImageGalleryComponent } from "../image-gallery/image-gallery.component";
import { EditJobComponent } from "../edit-job/edit-job.component";
import { UploadType } from "src/app/viewmodels/generated/upload-type";
import { EditContactComponent } from "../edit-contact/edit-contact.component";
import { Contact } from "src/app/viewmodels/generated/contact";
import { WarningAreyousureComponent } from "../warning-areyousure/warning-areyousure.component";
import { UpdateSiteContactsViewModel } from "src/app/viewmodels/generated/update-site-contacts-view-model";
import { FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";
import { EditSiteMaintenanceComponent } from "../edit-site-maintenance/edit-site-maintenance.component";
import { PrintQueueService } from "src/app/service/printqueue.service";

@Component({
  selector: "app-site-summary",
  templateUrl: "./site-summary.component.html",
  styleUrls: ["./site-summary.component.scss"],
})
export class SiteSummaryComponent implements OnInit, OnDestroy {
  route$: Subscription;
  siteId: number;
  siteSummary: SiteSummaryViewModel;

  imageUploads: SitePendingImageUpload[] = [];
  imageUploadId = 0;
  imageuploaded$: Subscription;
  siteUpdate$: Subscription;
  jobUpdated$: Subscription;

  jobStatuses = JobStatus;

  uploadType = UploadType;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private modalService: NgbModal,
    private signalr: SignalRService,
    private toastr: ToastrService,
    private printQueueService: PrintQueueService
  ) { }

  ngOnInit() {
    this.route$ = this.route.params.subscribe((routeParams) => {
      this.siteId = +routeParams.id;
      this.getSite(this.siteId);
    });

    this.siteUpdate$ = this.signalr.siteUpdateObserver.subscribe((args) => {
      if (this.siteId === +args) {
        this.getSite(this.siteId);
      }
    });

    this.imageuploaded$ = this.signalr.imageUploadedObserver.subscribe(
      (args) => {
        if (this.siteId === +args.siteId) {
          this.getSite(this.siteId);
        }
      }
    );

    this.jobUpdated$ = this.signalr.jobUpdatedObserver.subscribe((args) => {
      if (this.siteSummary.jobs.find(j => j.id === args.jobId)) {
        this.getSite(this.siteId);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.route$) {
      this.route$.unsubscribe();
    }
    if (this.siteUpdate$) {
      this.siteUpdate$.unsubscribe();
    }
  }

  getSite(siteId) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("id", siteId.toString());
    this.http
      .get<SiteSummaryViewModel>(ApiEndpoint.site.summary, {
        params: queryParams,
      })
      .pipe(retryWhen(delay(100)))
      .subscribe((res) => {
        this.siteSummary = res;
      });
  }

  getAddressParts() {
    return this.siteSummary.site.fullAddress.split(", ");
  }

  editAddress() {
    const modalRef = this.modalService.open(EditSiteAddressComponent, {
      size: "lg",
    });
    modalRef.componentInstance.siteSummary = { ...this.siteSummary };
  }

  editMaintenance() {
    const modalRef = this.modalService.open(EditSiteMaintenanceComponent, {
      size: "lg",
    });
    modalRef.componentInstance.site = { ...this.siteSummary.site };
  }

  editSystem(system: SiteSystem) {
    const modalRef = this.modalService.open(EditSystemComponent, {
      size: "lg",
    });
    modalRef.componentInstance.siteSummary = { ...this.siteSummary };
    modalRef.componentInstance.system = { ...system };
  }

  editJob(job: JobState) {
    const modalRef = this.modalService.open(EditJobComponent, {
      size: "lg",
    });
    modalRef.componentInstance.site = { ...this.siteSummary.site };
    modalRef.componentInstance.job = { ...job };
  }

  openSystemCreate() {
    const modalRef = this.modalService.open(EditSystemComponent, {
      size: "lg",
    });
    modalRef.componentInstance.siteSummary = { ...this.siteSummary };
    modalRef.componentInstance.system = null;
  }

  getJobDescriptionWithNotes(job: JobState) {
    return job.descriptionWithNotes.replace("\n", "<br />");
  }

  getSystemType(system: SiteSystem) {
    switch (system.isMotorised) {
      case SiteSystemIsMotorised.Yes:
        return "Motorised";
      case SiteSystemIsMotorised.No:
        return "Manual";
      default:
        return SiteSystemIsMotorised[system.isMotorised];
    }
  }

  getSystemIsOurs(system: SiteSystem) {
    return SiteSystemIsOurs[system.isOurs];
  }

  getSystemNotes(system: SiteSystem) {
    let toAdd: string = null;

    if (system.isHighLevel) {
      toAdd += "<b>Warning: System is high level</b>";
    }

    if (!system.notes && !toAdd) {
      return null;
    }

    if (!system.notes) {
      return toAdd;
    }
    if (!toAdd) {
      return system.notes;
    }
    return `${system.notes}<br />${toAdd}`;
  }

  imageFileDropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.type.indexOf("image") === -1) {
            this.toastr.error(
              "Not an image",
              `Error uploading ${droppedFile.relativePath}`
            );
            return;
          }

          const formData = new FormData();
          formData.append("siteid", this.siteId.toString());
          formData.append("file", file, droppedFile.relativePath);

          const uploadId = this.imageUploadId++;

          const uploadEnt: SitePendingImageUpload = {
            id: uploadId,
            uploadPercentage: 0,
            completed: false,
            errored: false,
            filename: droppedFile.relativePath,
            uploadLoadedKB: 0,
            uploadTotalKB: Math.ceil(file.size / 1024),
          };

          this.imageUploads.push(uploadEnt);

          this.http
            .post(ApiEndpoint.site.uploadImage, formData, {
              reportProgress: true,
              observe: "events",
            })
            .subscribe(
              (res) => {
                if (res.type === HttpEventType.UploadProgress) {
                  uploadEnt.uploadPercentage = 100 * (res.loaded / res.total);
                  uploadEnt.uploadLoadedKB = Math.ceil(res.loaded / 1024);
                  uploadEnt.uploadTotalKB = Math.ceil(res.total / 1024);
                }

                if (res.type === HttpEventType.Response) {
                  uploadEnt.completed = true;
                  this.toastr.success(`Uploaded ${droppedFile.relativePath}`);
                }
              },
              (err) => {
                uploadEnt.completed = true;
                uploadEnt.errored = true;
                this.toastr.error(
                  err.error.error
                    ? `${err.status} - ${err.error.error}`
                    : err.message,
                  `Error uploading ${droppedFile.relativePath}`
                );
              }
            );
        });
      }
    }
  }

  getFilteredImageUploads() {
    return this.imageUploads.filter((a) => !a.completed && !a.errored);
  }

  openImageGalleryAt(image: UploadState) {
    const modalRef = this.modalService.open(ImageGalleryComponent, {
      windowClass: "xl-modal-window",
    });
    modalRef.componentInstance.site = this.siteSummary.site;
    modalRef.componentInstance.images = this.siteSummary.recentImages;
    modalRef.componentInstance.initialImage = image;
  }

  getImageRotation(image: UploadState) {
    if (!image.rotation) {
      return 0;
    }
    return image.rotation;
  }

  addJob() {
    const modalRef = this.modalService.open(EditJobComponent, {
      size: "lg",
    });
    modalRef.componentInstance.site = this.siteSummary.site;
    modalRef.componentInstance.job = null;
  }

  createButtonTooltip() {
    if (
      this.siteSummary &&
      this.siteSummary.site &&
      !this.siteSummary.site.addressLat &&
      !this.siteSummary.site.addressLng
    ) {
      return "This site must have a map position before you can create jobs";
    }
    return "";
  }

  getServiceReminderType(val: UploadState) {
    switch (val.type) {
      case UploadType.ServiceReminderEmail:
        return "E-mail";
      case UploadType.ServiceReminderLetter:
        return "Letter";
      default:
        return "Unknown";
    }
  }

  sendServiceReminder() {
    //TODO: Add e-mail support
    this.printQueueService.queue.items.push({
      type: "maintenancereminder",
      arguments: { siteId: this.siteId.toString() },
      url: null,
      error: null,
    });
    this.printQueueService.put();
  }

  openServiceReminderView(val: UploadState) {
    let url = val.mediaLink;

    // If it's a letter lets pass it to google docs viewer
    if (val.type === UploadType.ServiceReminderLetter) {
      url = `https://docs.google.com/viewer?url=${encodeURI(val.mediaLink)}`;
    }

    window.open(url);
  }

  createContact() {
    const modalRef = this.modalService.open(EditContactComponent, {
      size: "lg",
    });

    modalRef.componentInstance.site = this.siteSummary.site;
  }

  getContactName(val: Contact) {
    if (val.name) {
      return val.name;
    }
    if (val.emails.length) {
      return val.emails[0].email;
    }
    return "Unknown";
  }

  editContact(val: Contact) {
    const modalRef = this.modalService.open(EditContactComponent, {
      size: "lg",
    });

    modalRef.componentInstance.site = this.siteSummary.site;
    modalRef.componentInstance.contact = val;
  }

  getContacts() {
    return this.siteSummary.site.contacts.filter((a) => !a.deleted);
  }

  deleteContact(val: Contact) {
    const modalRef = this.modalService.open(WarningAreyousureComponent, {
      size: "lg",
    });

    modalRef.componentInstance.title = "Delete contact";
    modalRef.componentInstance.message =
      "Are you sure you want to delete this contact?";

    modalRef.result.then((res) => {
      if (res) {
        val.deleted = true;

        const request: UpdateSiteContactsViewModel = {
          siteId: this.siteId,
          contacts: this.siteSummary.site.contacts,
        };

        this.http.put(ApiEndpoint.site.putContacts, request).subscribe(
          () => {
            this.toastr.success("Contacts updated");
          },
          () => {
            this.toastr.error("Error updating contacts");
          }
        );
      }
    });
  }
}
