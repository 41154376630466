<div *ngIf="siteUpdated" class="alert alert-warning text-center" role="alert">
    This site has been modified and the information is out of date.<br />You must
    re-open this form to carry out any changes.
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col font-weight-bold text-center site-summary-header">
                        Maintenance Costs
                    </div>
                </div>

                <div class="row">
                    <div class="col">&nbsp;</div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="font-weight-bold text-center site-summary-header">
                            Motorised system
                        </div>
                        <input type="text" formControlName="motorised" class="form-control" />
                        <div class="alert alert-danger" *ngIf="form.get('motorised').errors">Not a valid number.</div>
                    </div>
                    <div class="col">
                        <div class="font-weight-bold text-center site-summary-header">
                            Manual system
                        </div>
                        <input type="text" formControlName="manual" class="form-control" />
                        <div class="alert alert-danger" *ngIf="form.get('manual').errors">Not a valid number.</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-3"></div>
                    <div class="col">
                        <div class="font-weight-bold text-center site-summary-header">
                            Attendance
                        </div>
                        <input type="text" formControlName="attendance" class="form-control" />
                        <div class="alert alert-danger" *ngIf="form.get('attendance').errors">Not a valid number.</div>
                    </div>
                    <div class="col-3"></div>
                </div>

                <div class="row">
                    <div class="col">&nbsp;</div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="font-weight-bold text-center site-summary-header">
                            Reason
                        </div>
                        <input type="text" formControlName="reason" class="form-control" />
                    </div>
                </div>
                <div class="row" *ngIf="reasonMissing">
                    <div class="col">
                        <div class="alert alert-danger">
                            You must provide a reason.
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">&nbsp;</div>
                </div>

                <div class="row">
                    <div class="col text-right">
                        <button class="btn btn-success" [disabled]="!form.valid || siteUpdated">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>