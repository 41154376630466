import { Component, OnInit, OnDestroy } from '@angular/core';
import { SiteGalleryResponse } from 'src/app/viewmodels/generated/site-gallery-response';
import { ActivatedRoute, Router } from '@angular/router';
import { SignalRService } from 'src/app/signal-r.service';
import { Subscription } from 'rxjs';
import { SiteGalleryRequest } from 'src/app/viewmodels/generated/site-gallery-request';
import { HttpClient } from '@angular/common/http';
import { ApiEndpoint } from 'src/app/enum/apiendpoints.enum';
import { retryWhen, delay } from 'rxjs/operators';
import { ImageGalleryComponent } from '../image-gallery/image-gallery.component';
import { UploadState } from 'src/app/viewmodels/generated/upload-state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-site-gallery',
  templateUrl: './site-gallery.component.html',
  styleUrls: ['./site-gallery.component.scss']
})
export class SiteGalleryComponent implements OnInit, OnDestroy {
  siteGallery: SiteGalleryResponse;
  route$: Subscription;
  siteId: number;
  imageUploaded$: Subscription;

  constructor(
    private http: HttpClient,
    private signalr: SignalRService,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.route$ = this.route.params.subscribe(routeParams => {
      this.siteId = +routeParams.id;
      this.getGallery();
    });

    this.imageUploaded$ = this.signalr.imageUploadedObserver.subscribe(res => {
      if (res.siteId === this.siteId) {
        this.getGallery();
      }
    });
  }

  ngOnDestroy() {
    if (this.imageUploaded$) {
      this.imageUploaded$.unsubscribe();
    }
  }

  openImageGalleryAt(image: UploadState) {
    const modalRef = this.modalService.open(ImageGalleryComponent, {
      windowClass: 'xl-modal-window'
    });
    modalRef.componentInstance.site = this.siteGallery.site;
    modalRef.componentInstance.images = this.siteGallery.images;
    modalRef.componentInstance.initialImage = image;
  }

  getGallery() {
    if (!this.siteId) {
      return;
    }

    const request: SiteGalleryRequest = {
      siteId: this.siteId
    };

    this.http
      .post<SiteGalleryResponse>(ApiEndpoint.site.gallery, request)
      .pipe(retryWhen(delay(500)))
      .subscribe(res => {
        this.siteGallery = res;
      });
  }

  getImageRotation(image: UploadState) {
    if (!image.rotation) {
      return 0;
    }
    return image.rotation;
  }
}
