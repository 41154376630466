import { mergeMap } from 'rxjs/operators';
import { AuthService } from './../service/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (
      request.url.indexOf('forgeleisure.host') !== -1 ||
      (request.url.indexOf('http://') === -1 &&
        request.url.indexOf('https://') === -1)
    ) {
      return this.authService.getAuthTokenObservable().pipe(
        mergeMap(res => {
          if (res !== null) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${res}`
              }
            });
          }
          return next.handle(request);
        })
      );
    }
    return next.handle(request);
  }
}
