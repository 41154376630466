<app-site-navbar current="jobs"></app-site-navbar>

<div class="container" *ngIf="!siteJobView">
  <div class="row">
    <div class="col d-flex justify-content-center">
      <mat-spinner clas="text-center"></mat-spinner>
    </div>
  </div>
</div>

<div class="container" *ngIf="siteJobView && siteSummary">
  <div class="row">
    <div class="col">
      &nbsp;
    </div>
  </div>
  <div class="row">
    <div class="col">
      <table
        matSort
        (matSortChange)="sortData($event)"
        matSortDisableClear
        class="table table-striped table-bordered"
      >
        <tr>
          <th mat-sort-header="id" class="mat-sort-header-center">ID</th>
          <th mat-sort-header="type" class="mat-sort-header-center">Type</th>
          <th mat-sort-header="desc">
            Description
          </th>
          <th mat-sort-header="status" class="mat-sort-header-center">
            Status
          </th>
          <th mat-sort-header="lasttupdated" class="mat-sort-header-center">
            Last Updated
          </th>
          <th mat-sort-header="isassigned" class="mat-sort-header-center">
            Visit Booked
          </th>
          <th class="mx-auto text-center">
            <span [ngbTooltip]="createButtonTooltip()"
              ><button
                type="button"
                class="btn btn-primary"
                (click)="addJob()"
                [disabled]="
                  !this.siteSummary.site.addressLat &&
                  !this.siteSummary.site.addressLng
                "
              >
                Create
              </button></span
            >
          </th>
        </tr>

        <tr *ngFor="let job of siteJobView.jobs">
          <td class="text-center">{{ job.id }}</td>
          <td class="text-center">{{ jobService.getJobType(job) }}</td>
          <td [innerHTML]="getJobDescriptionWithNotes(job) | noEscapeHtml"></td>
          <td class="text-center">{{ jobStatuses[job.status] }}</td>
          <td class="text-center">
            {{ job.lastUpdated | date: "dd/MM/yy HH:mm:ss" }}
          </td>
          <td class="text-center">{{ isJobVisitBooked(job) }}</td>
          <td class="mx-auto text-center">
            <button class="btn btn-primary" (click)="editJob(job)">Edit</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
