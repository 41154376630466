<div class="container">
  <div class="row">
    <div class="col-3"></div>
    <div class="col-6">
      <div class="text-center font-weight-bold">Print Queue</div>
      <div class="text-center font-weight-bold" *ngIf="
          printQueueService.queue &&
          printQueueService.queue.items &&
          printQueueService.queue.items.length > 0
        ">
        {{ getGeneratedCount() }} / {{ printQueueService.queue.items.length }}
      </div>
    </div>
    <div class="col-3 text-right">
      <button class="btn btn-primary" (click)="printQueue()"
        [disabled]="!getFinalUrl(printQueueService.queue)">Print</button>
      <button class="btn btn-danger" (click)="clearPrintQueue()">Clear</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <table class="table table-striped table-bordered" *ngIf="
          printQueueService.queue &&
          printQueueService.queue.items &&
          printQueueService.queue.items.length > 0
        ">
        <tr>
          <th class="text-center">Description</th>
          <th class="text-center">Status</th>
          <th class="text-center">Preview</th>
        </tr>

        <tr *ngFor="let item of printQueueService.queue.items">
          <td class="text-center">
            <span *ngIf="item.type === 'jobsheet'">Jobsheet for job {{ item.arguments["jobId"] }} <br /><button
                (click)="editJob(item.arguments['jobId'])" class="btn btn-primary">
                Open Job
              </button></span>
          </td>
          <td class="text-center">
            <span *ngIf="item.url">Done</span>
            <span *ngIf="item.error">Error</span>
            <span *ngIf="!item.error && !item.url">Generating...</span>
          </td>
          <td class="text-center">
            <a [href]="getFileUrl(printQueueService.queue, item)" *ngIf="item.url">View</a>
            <span *ngIf="item.error">{{ item.error }}</span>
          </td>
        </tr>
      </table>

      <div class="text-center" *ngIf="
          !printQueueService.queue ||
          !printQueueService.queue.items ||
          printQueueService.queue.items.length === 0
        ">
        There are no items in your print queue
      </div>
    </div>
  </div>
</div>