import {
  Component,
  OnInit,
  Input,
  HostListener,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Site } from 'src/app/viewmodels/generated/site';
import { UploadState } from 'src/app/viewmodels/generated/upload-state';
import { ContentObserver } from '@angular/cdk/observers';
import { ApiEndpoint } from 'src/app/enum/apiendpoints.enum';
import { HttpClient } from '@angular/common/http';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {
  @Input() site: Site;
  @Input() images: UploadState[];
  @Input() initialImage: UploadState;
  currentImage: UploadState;
  currentImageWidth: number;
  currentImageHeight: number;

  @ViewChild('imageCanvas', { static: true }) imageCanvas: ElementRef;
  currentCanvas: HTMLCanvasElement;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    if (this.initialImage) {
      this.setImage(this.initialImage);
    } else if (this.images.length > 0) {
      this.setImage(this.images[0]);
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.nextImage();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.previousImage();
    }
  }

  getImageRotation(image: UploadState) {
    if (!image.rotation) {
      return 0;
    }
    return image.rotation;
  }

  buildBottomSlideshow() {
    const currentImageIndex = this.images.indexOf(this.currentImage);

    // Show 5 images with current image in centre (if possible)
    let start = Math.max(currentImageIndex - 2, 0);
    let end = start + 5;

    if (end >= this.images.length) {
      start = Math.max(this.images.length - 5, 0);
      end = this.images.length;
    }

    return this.images.slice(start, end);
  }

  nextImage() {
    const newIndex = Math.min(
      this.images.indexOf(this.currentImage) + 1,
      this.images.length - 1
    );
    this.setImage(this.images[newIndex]);
  }

  previousImage() {
    const newIndex = Math.max(this.images.indexOf(this.currentImage) - 1, 0);
    this.setImage(this.images[newIndex]);
  }

  setImage(image: UploadState) {
    this.currentImage = image;
    this.reloadCurrentImage();
  }

  reloadCurrentImage() {
    const img = new Image();
    img.src = this.currentImage.mediaLink;
    img.onload = () => {
      this.currentCanvas = this.imageCanvas.nativeElement as HTMLCanvasElement;
      const ctx = this.currentCanvas.getContext('2d');

      ctx.clearRect(0, 0, this.currentCanvas.width, this.currentCanvas.height);
      // Update canvas to the image rotation (CSS will clamp it)
      // If at a right angle rotation, we flip width and height on the canvas
      // So constraints are retained
      if (
        this.currentImage.rotation &&
        Math.abs(this.currentImage.rotation) % 180 === 90
      ) {
        ctx.canvas.width = img.height;
        ctx.canvas.height = img.width;
      } else {
        ctx.canvas.width = img.width;
        ctx.canvas.height = img.height;
      }
      ctx.save();
      ctx.translate(
        this.currentCanvas.width / 2,
        this.currentCanvas.height / 2
      );
      if (this.currentImage.rotation) {
        ctx.rotate((this.currentImage.rotation * Math.PI) / 180);
      }
      ctx.drawImage(img, -img.width / 2, -img.height / 2);
      ctx.restore();
    };
  }

  getFileName(fullPath: string) {
    return fullPath.split('/').pop();
  }

  rotateImageRight() {
    if (!this.currentImage.rotation) {
      this.currentImage.rotation = 0;
    }

    this.currentImage.rotation += 90;

    this.http
      .put(ApiEndpoint.upload.put(this.currentImage.id), this.currentImage)
      .subscribe(() => {
        this.reloadCurrentImage();
      });
  }

  rotateImageLeft() {
    if (!this.currentImage.rotation) {
      this.currentImage.rotation = 0;
    }

    this.currentImage.rotation -= 90;

    this.http
      .put(ApiEndpoint.upload.put(this.currentImage.id), this.currentImage)
      .subscribe(() => {
        this.reloadCurrentImage();
      });
  }

  getCanvasWidth() {
    if (!this.currentCanvas) {
      return 0;
    }
    return this.currentCanvas.width;
  }

  getCanvasHeight() {
    if (!this.currentCanvas) {
      return 0;
    }
    return this.currentCanvas.height;
  }
}
