<div class="container">
  <div class="row">
    <div class="col font-weight-bold text-center site-summary-header">
      {{ title }}
    </div>
  </div>
  <div class="row">
    <div class="col">
      {{ message }}
    </div>
  </div>
  <div class="row">
    <div class="col text-right">
      <button type="button" class="btn btn-warning" (click)="onCancel()">
        Cancel
      </button>
      <button type="button" class="btn btn-success" (click)="onOk()">Ok</button>
    </div>
  </div>
</div>
