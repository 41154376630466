import { JobOptions } from "./../../viewmodels/generated/job-options";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { JobState } from "src/app/viewmodels/generated/job-state";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { JobStatus } from "src/app/viewmodels/generated/job-status";
import { JobType } from "src/app/viewmodels/generated/job-type";
import { JobEntry } from "src/app/viewmodels/generated/job-entry";
import { HttpClient } from "@angular/common/http";
import { ApiEndpoint } from "src/app/enum/apiendpoints.enum";
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JobService } from "src/app/service/job.service";
import { Subscription } from "rxjs";
import { SignalRService } from "src/app/signal-r.service";
import { SiteSystemIsMotorised } from "src/app/viewmodels/generated/site-system-is-motorised";
import { Site } from "src/app/viewmodels/generated/site";
import * as moment from "moment";

@Component({
  selector: "app-edit-job",
  templateUrl: "./edit-job.component.html",
  styleUrls: ["./edit-job.component.scss"],
})
export class EditJobComponent implements OnInit, OnDestroy {
  @Input() site: Site;
  @Input() job: JobState;

  form: FormGroup;

  jobStatuses = JobStatus;
  jobTypes = JobType;

  disabled: boolean;
  saving: boolean;
  formchange$: Subscription;
  showUpdatedWarning: boolean;
  jobUpdated$: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private jobservice: JobService,
    private signalr: SignalRService,
    private jobService: JobService
  ) { }

  ngOnInit() {
    if (!this.job) {
      this.job = {
        id: 0,
        siteId: this.site.id,
        lat: this.site.addressLat,
        long: this.site.addressLng,
        timeCreated: new Date(),
        lastUpdated: new Date(),
        timeCompleted: null,
        dueDate: null,
        status: JobStatus.Pending,
        masternautReference: null,
        addMasternaut: false,
        assignedTimeStart: null,
        assignedTimeEnd: null,
        assignedUsersList: [],
        assignedText: null,
        description: "",
        descriptionWithNotes: "",
        entries: [],
        rowVersion: null,
      };
    }

    this.jobUpdated$ = this.signalr.jobUpdatedObserver.subscribe((args) => {
      if (args.jobId === this.job.id) {
        this.showUpdatedWarning = true;
        this.disabled = true;
        this.form.disable();
      }
    });

    this.form = this.formBuilder.group({
      description: { value: this.job.descriptionWithNotes, disabled: true }, // Computed, cannot modify
      status: {
        value: this.job.status,
        disabled:
          this.job.status === JobStatus.Legacy ||
          this.job.status === JobStatus.Deleted,
      },
      timeCompleted: { value: this.job.timeCompleted, disabled: true },
      dueDate: {
        value: this.job.dueDate
          ? moment(this.job.dueDate).format("YYYY-MM-DD")
          : null,
        disabled:
          this.job.status === JobStatus.Legacy ||
          this.job.status === JobStatus.Deleted ||
          this.job.status === JobStatus.Complete,
      },
      entries: this.formBuilder.array([]),
    });

    this.formchange$ = this.form.valueChanges.subscribe(() => {
      this.updateJobFromForm();
      setTimeout(() => this.updateJobDescription());
    });

    this.job.entries.forEach((entry) => {
      const entries = this.getEntriesArray();
      entries.push(
        this.formBuilder.group({
          systemId: { value: entry.systemId, disabled: false },
          type: { value: entry.type, disabled: false },
          completed: { value: entry.completed, disabled: false },
          requiresReturnVisit: {
            value: entry.requiresReturnVisit,
            disabled: false,
          },
          description: { value: entry.description, disabled: false },
          notes: { value: entry.notes, disabled: false },
        })
      );
    });

    if (
      this.job.status === JobStatus.Legacy ||
      this.job.status === JobStatus.Complete ||
      this.job.status === JobStatus.Cancelled ||
      this.job.status === JobStatus.Deleted
    ) {
      this.form.disable();
      this.disabled = true;
    }
  }

  ngOnDestroy() {
    if (this.formchange$) {
      this.formchange$.unsubscribe();
    }
    if (this.jobUpdated$) {
      this.jobUpdated$.unsubscribe();
    }
  }

  getEntriesArray() {
    return this.form.get("entries") as FormArray;
  }

  moveJobEntryDown(index: number) {
    if (index + 1 >= this.job.entries.length) {
      return;
    }

    const a = this.job.entries[index];
    const b = this.job.entries[index + 1];
    this.job.entries[index + 1] = a;
    this.job.entries[index] = b;

    const formArray = this.getEntriesArray();
    const formArrayValue = [...formArray.value];
    const formA = formArrayValue[index];
    const formB = formArrayValue[index + 1];
    formArrayValue[index + 1] = formA;
    formArrayValue[index] = formB;
    formArray.setValue(formArrayValue);
  }

  moveJobEntryUp(index: number) {
    if (index === 0) {
      return;
    }

    const a = this.job.entries[index];
    const b = this.job.entries[index - 1];
    this.job.entries[index - 1] = a;
    this.job.entries[index] = b;

    const formArray = this.getEntriesArray();
    const formArrayValue = [...formArray.value];
    const formA = formArrayValue[index];
    const formB = formArrayValue[index - 1];
    formArrayValue[index - 1] = formA;
    formArrayValue[index] = formB;
    formArray.setValue(formArrayValue);
  }

  updateJobDescription() {
    this.job.description = this.jobservice.generateDescription(
      this.job,
      this.site,
      false
    );
    this.job.descriptionWithNotes = this.jobservice.generateDescription(
      this.job,
      this.site,
      true
    );

    this.form.get("description").setValue(this.job.descriptionWithNotes);
  }

  createJobEntry() {
    const entry: JobEntry = {
      systemId: 0,
      type: JobType.Unknown,
      description: "",
      notes: "",
      completed: false,
      requiresReturnVisit: false,
      timeCreated: new Date(),
      timeUpdated: new Date(),
      options: [],
    };

    return entry;
  }

  addJobEntry(entry: JobEntry = null) {
    if (!entry) {
      entry = this.createJobEntry();
    }

    this.job.entries.push(entry);

    const entries = this.getEntriesArray();
    entries.push(
      this.formBuilder.group({
        systemId: { value: entry.systemId, disabled: false },
        type: { value: entry.type, disabled: false },
        completed: { value: entry.completed, disabled: false },
        requiresReturnVisit: {
          value: entry.requiresReturnVisit,
          disabled: false,
        },
        description: { value: entry.description, disabled: false },
        notes: { value: entry.notes, disabled: false },
      })
    );
  }

  updateJobFromForm() {
    this.job.status = this.form.get("status").value;

    if (this.form.get("dueDate").value) {
      this.job.dueDate = new Date(this.form.get("dueDate").value);
    } else {
      this.job.dueDate = null;
    }

    const formEntries = this.getEntriesArray();

    formEntries.controls.forEach((entryControl, index) => {
      if (entryControl instanceof FormGroup) {
        const entryFormControl = entryControl as FormGroup;

        const entry = this.job.entries[index];

        entry.systemId = entryFormControl.get("systemId").value;
        entry.type = entryFormControl.get("type").value;
        entry.completed = entryFormControl.get("completed").value;
        entry.requiresReturnVisit = entryFormControl.get(
          "requiresReturnVisit"
        ).value;
        entry.description = entryFormControl.get("description").value;
        entry.notes = entryFormControl.get("notes").value;
      }
    });
  }

  onSubmit() {
    if (this.job.id === 0) {
      // Creating a new job
      this.jobservice.create(this.job).subscribe(
        () => {
          this.toastr.success("Job created");
          this.modal.close(true);
        },
        (err) => {
          this.toastr.error(err.message, "Job creation error");
        }
      );
    } else {
      this.jobservice.update(this.job).subscribe(
        () => {
          this.toastr.success("Job updated");
          this.modal.close(true);
        },
        (err) => {
          this.toastr.error(err.message, "Job update error");
        }
      );
    }
  }

  createTemplateService(withWeldIntegrityTest: boolean = false) {
    this.site.systems.forEach((system) => {
      const jobEntry = this.createJobEntry();

      jobEntry.systemId = system.id;
      jobEntry.type = JobType.Service;

      switch (system.isMotorised) {
        case SiteSystemIsMotorised.Yes:
          {
            jobEntry.description = "Motorised service";

            if (withWeldIntegrityTest) {
              jobEntry.description += this.getJobOptionDescription(JobOptions.WeldIntegrityTest);
              jobEntry.options.push(JobOptions.WeldIntegrityTest);
            }
          }
          break;
        case SiteSystemIsMotorised.No:
          {
            jobEntry.description = "Manual service";
          }
          break;
        case SiteSystemIsMotorised.Unknown:
          {
            jobEntry.description = "Service";

            if (withWeldIntegrityTest) {
              jobEntry.description += this.getJobOptionDescription(JobOptions.WeldIntegrityTest);
              jobEntry.options.push(JobOptions.WeldIntegrityTest);
            }
          }
          break;
      }

      this.addJobEntry(jobEntry);
    });
  }

  getJobOptions(entryIndex: number) {
    switch (+this.job.entries[entryIndex].type) {
      case JobType.Installation:
      case JobType.ReplacementCover:
        return [JobOptions.RemovalAndDisposal];
      case JobType.Service:
        return [JobOptions.WeldIntegrityTest];
      default:
        return null;
    }
  }

  jobOptionSelected(entryIndex: number, option: JobOptions) {
    if (
      !this.job ||
      !this.job.entries ||
      this.job.entries.length <= entryIndex ||
      !this.job.entries[entryIndex].options
    ) {
      return false;
    }

    return (
      this.job.entries[entryIndex].options.find((a) => a === option) !==
      undefined
    );
  }

  jobOptionChange(event, entryIndex: number, option: JobOptions) {
    if (
      !this.job ||
      !this.job.entries ||
      this.job.entries.length <= entryIndex
    ) {
      return;
    }

    if (!this.job.entries[entryIndex].options) {
      this.job.entries[entryIndex].options = [];
    }

    var formEntities = this.getEntriesArray();
    var entityFormGroup = formEntities.at(entryIndex) as FormGroup;

    if (event.target.checked) {
      this.job.entries[entryIndex].options.push(option);

      if (entityFormGroup) {
        var description = entityFormGroup.get('description');
        description.setValue(description.value + `\n${this.getJobOptionDescription(option)}`);
        this.updateJobFromForm();
      }
    }
    else {
      this.job.entries[entryIndex].options = this.job.entries[entryIndex].options.filter(o => o !== option);
      this.job.entries[entryIndex].description = this.job.entries[entryIndex].description.replace(`\n${this.getJobOptionDescription(option)}`, '');

      if (entityFormGroup) {
        var description = entityFormGroup.get('description');
        description.setValue(description.value.replace(`\n${this.getJobOptionDescription(option)}`, ''));
        this.updateJobFromForm();
      }
    }
  }

  getJobOptionText(option: JobOptions) {
    switch (option) {
      case JobOptions.RemovalAndDisposal:
        return "Removal and Disposal";
      case JobOptions.WeldIntegrityTest:
        return "Weld Integrity Test";
      default:
        return "Unknown";
    }
  }

  getJobOptionDescription(option: JobOptions) {
    switch (option) {
      case JobOptions.RemovalAndDisposal:
        return "Removal and disposal of old.";
      case JobOptions.WeldIntegrityTest:
        return "Carry out weld integrity test.";
      default:
        return "Unknown.";
    }
  }
}
