import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyOrUnknown'
})
export class CurrencyOrUnknownPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(value: any, currencyCode: string): string {
    if (!value) {
      return "Unknown";
    }

    return this.currencyPipe.transform(value, currencyCode);
  }

}
