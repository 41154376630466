import { Site } from "src/app/viewmodels/generated/site";
import { ApiEndpoint } from "src/app/enum/apiendpoints.enum";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SiteService {
  constructor(private http: HttpClient) {}

  get(id: number) {
    return this.http.get<Site>(ApiEndpoint.site.get(id));
  }
}
