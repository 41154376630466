import { SiteService } from "./../../service/site.service";
import { JobService } from "src/app/service/job.service";
import { environment } from "./../../../environments/environment";
import { PrintQueueService } from "./../../service/printqueue.service";
import { PrintQueueItem } from "./../../viewmodels/generated/print-queue-item";
import { Component, OnInit, ViewChild } from "@angular/core";
import { PrintQueue } from "src/app/viewmodels/generated/print-queue";
import { EditJobComponent } from "../edit-job/edit-job.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { take } from "rxjs/operators";

@Component({
  selector: "app-printqueue",
  templateUrl: "./printqueue.component.html",
  styleUrls: ["./printqueue.component.sass"],
})
export class PrintQueueComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private jobService: JobService,
    private siteService: SiteService,
    public printQueueService: PrintQueueService) { }

  ngOnInit() { }

  getGeneratedCount() {
    return this.printQueueService.queue.items.filter((i) => i.url !== null)
      .length;
  }

  getFileUrl(queue: PrintQueue, item: PrintQueueItem) {
    if (!item) {
      return null;
    }

    return `${environment.storage}PrintQueue/${queue.id}/${item.url}`;
  }

  getFinalUrl(queue: PrintQueue) {
    if (!queue || !queue.url) {
      return;
    }
    return `${environment.storage}PrintQueue/${queue.id}/${queue.url}`;
  }

  async editJob(jobId: number) {
    const jobState = await this.jobService.get(jobId).pipe(take(1)).toPromise();
    const siteState = await this.siteService
      .get(jobState.siteId)
      .pipe(take(1))
      .toPromise();

    const modalRef = this.modalService.open(EditJobComponent, {
      size: "lg",
    });
    modalRef.componentInstance.job = jobState;
    modalRef.componentInstance.site = siteState;
  }

  clearPrintQueue() {
    this.printQueueService.queue.items = [];
    this.printQueueService.put();
  }

  printQueue() {
    var finalUrl = this.getFinalUrl(this.printQueueService.queue);

    if (!finalUrl) {
      return;
    }

    window.open(finalUrl);
  }
}
