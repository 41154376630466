import { ApiEndpoint } from "./enum/apiendpoints.enum";
import { AuthService } from "./service/auth.service";
import { Injectable } from "@angular/core";
import {
  HubConnectionBuilder,
  LogLevel,
  HubConnection,
  HttpTransportType,
} from "@aspnet/signalr";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { JobState } from "./viewmodels/generated/job-state";
import { PrintQueue } from "./viewmodels/generated/print-queue";
import { Site } from "./viewmodels/generated/site";

@Injectable({
  providedIn: "root",
})
export class SignalRService {
  connection: HubConnection;
  configEntryUpdatedObserver: Subject<string>;
  siteUpdateObserver: Subject<any>;
  siteAddressUpdateObserver: Subject<any>;
  siteSystemUpdateObserver: Subject<{ siteId: number; systemId: number }>;
  imageUploadedObserver: Subject<{ siteId: number }>;
  jobUpdatedObserver: Subject<{ jobId: number; siteId: number }>;
  vehicleBatchUpdate: Subject<any>;
  printQueueUpdate: Subject<PrintQueue>;

  constructor(private authService: AuthService) {
    this.connection = new HubConnectionBuilder()
      .withUrl(ApiEndpoint.signalR, {
        accessTokenFactory: () => authService.getJWT(),
      })
      .configureLogging(LogLevel.Information)
      .build();

    this.connection.onclose(() => this.OnConnectionClose());

    this.configEntryUpdatedObserver = new Subject<string>();
    this.connection.on("ConfigEntryUpdated", (id) =>
      this.configEntryUpdatedObserver.next(id)
    );

    this.siteAddressUpdateObserver = new Subject<any[]>();
    this.connection.on("SiteAddressUpdate", (siteId) =>
      this.siteAddressUpdateObserver.next(siteId)
    );

    this.siteUpdateObserver = new Subject<any[]>();
    this.connection.on("SiteUpdated", (siteId) =>
      this.siteUpdateObserver.next(siteId)
    );

    this.siteSystemUpdateObserver = new Subject<{
      siteId: number;
      systemId: number;
    }>();
    this.connection.on("SiteSystemUpdate", (siteId, systemId) =>
      this.siteSystemUpdateObserver.next({ siteId, systemId })
    );

    this.imageUploadedObserver = new Subject<{ siteId: number }>();
    this.connection.on("ImageUpload", (siteId) =>
      this.imageUploadedObserver.next({ siteId })
    );

    this.jobUpdatedObserver = new Subject<{ jobId: number; siteId: number }>();
    this.connection.on("JobUpdated", (jobId, siteId) =>
      this.jobUpdatedObserver.next({ siteId, jobId })
    );

    this.vehicleBatchUpdate = new Subject<{ jobId: number; siteId: number }>();
    this.connection.on("VehicleBatchUpdate", () =>
      this.vehicleBatchUpdate.next()
    );

    this.printQueueUpdate = new Subject<PrintQueue>();
    this.connection.on("PrintQueueUpdated", (item) =>
      this.printQueueUpdate.next(item)
    );

    this.OpenConnection();
  }

  async OpenConnection() {
    try {
      await this.connection.start();
    } catch (e) {
      await this.Delay(1000);
      this.OpenConnection();
    }
  }

  async OnConnectionClose() {
    await this.Delay(1000);
    await this.OpenConnection();
  }

  Delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
