import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiEndpoint } from 'src/app/enum/apiendpoints.enum';
import { SignalRService } from 'src/app/signal-r.service';
import { ServiceCost } from 'src/app/viewmodels/generated/service-cost';
import { Site } from 'src/app/viewmodels/generated/site';

@Component({
  selector: 'app-edit-site-maintenance',
  templateUrl: './edit-site-maintenance.component.html',
  styleUrls: ['./edit-site-maintenance.component.sass']
})
export class EditSiteMaintenanceComponent implements OnInit, OnDestroy {
  @Input() site: Site;

  siteUpdated$: Subscription;
  siteUpdated: boolean;
  form: FormGroup;
  reasonMissing: boolean;

  constructor(private signalr: SignalRService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private modal: NgbActiveModal,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.siteUpdated$ = this.signalr.siteUpdateObserver.subscribe(siteId => {
      if (siteId === this.site.id) {
        this.siteUpdated = true;
      }
    });

    this.form = this.formBuilder.group({
      motorised: [null, Validators.pattern(/^\d*(?:[.]\d{2})?$/)],
      manual: [null, Validators.pattern(/^\d*(?:[.]\d{2})?$/)],
      attendance: [null, Validators.pattern(/^\d*(?:[.]\d{2})?$/)],
      firstMotorised: [null, Validators.pattern(/^\d*(?:[.]\d{2})?$/)],
      firstManual: [null, Validators.pattern(/^\d*(?:[.]\d{2})?$/)],
      reason: [null],
    })

    if (this.site.serviceCost) {
      this.form.patchValue(this.site.serviceCost);
    }
  }

  ngOnDestroy(): void {
    if (this.siteUpdated$) {
      this.siteUpdated$.unsubscribe();
    }
  }

  getNumberOrNull(key) {
    const val = this.form.get(key).value;

    if (!val || val.length == 0) { return null; }

    return +val;
  }

  getUpdatedObject(): ServiceCost {
    return {
      motorised: this.getNumberOrNull('motorised'),
      manual: this.getNumberOrNull('manual'),
      attendance: this.getNumberOrNull('attendance'),
      firstMotorised: this.getNumberOrNull('firstMotorised'),
      firstManual: this.getNumberOrNull('firstManual'),
      reason: this.form.get('reason').value,
    }
  }

  onSubmit() {
    const updated = this.getUpdatedObject();

    if ((!updated.reason || updated.reason.length == 0) && (updated.motorised || updated.manual || updated.attendance || updated.firstMotorised || updated.firstManual)) {
      this.reasonMissing = true;
      return;
    }

    const updatedSite = { ...this.site };
    updatedSite.serviceCost = updated;

    this.http.put(ApiEndpoint.site.put(this.site.id), updatedSite).subscribe(() => {
      this.toastr.success('Site updated');
      this.modal.close();
    },
      err => {
        this.toastr.error(err.message, 'Error updating site');
      });
  }
}
