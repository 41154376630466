<div class="container">
    <div class="row">
        <div class="col text-center"><button class="btn btn-success" (click)="addSetting()">Add</button></div>
    </div>

    <table class="table table-striped table-bordered">
        <th class="text-center">Key</th>
        <th class="text-center">Value</th>
        <th class="text-center">&nbsp;</th>
        <tr *ngFor="let config of configurations">
            <td>{{config.id}}</td>
            <td>{{config.value}}</td>
            <td><button class="btn btn-primary" (click)="editConfig(config)">Edit</button></td>
        </tr>
    </table>
</div>