import { AuthService } from './../../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navigationbar',
  templateUrl: './navigationbar.component.html',
  styleUrls: ['./navigationbar.component.scss']
})
export class NavigationbarComponent implements OnInit {
  isAdmin: Observable<boolean>;
  constructor(private authService: AuthService) {
    this.isAdmin = this.authService.hasClaim('isAdmin');
  }

  ngOnInit() {}
}
