<div class="container-fluid">
  <div class="row">
    <div class="col-3">&nbsp;</div>
    <div class="col-6">
      <input
        type="text"
        placeholder="Search"
        [formControl]="searchTextControl"
        class="form-control"
        (focus)="addSearchResults()"
        (blur)="removeSearchResults()"
      />
    </div>
    <div class="col-3 text-right">
      <button type="button" class="btn btn-primary" (click)="filterToggle()">
        Filters <i class="fas fa-caret-down" *ngIf="!filtersOpen"></i
        ><i class="fas fa-caret-up" *ngIf="filtersOpen"></i>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="recentlyUpdatedToggle()"
      >
        Recently Updated
        <i class="fas fa-caret-down" *ngIf="!recentlyUpdatedOpen"></i
        ><i class="fas fa-caret-up" *ngIf="recentlyUpdatedOpen"></i>
      </button>
    </div>
  </div>
</div>
<div class="container">
  <div style="overflow-y: visible; height: 0;" *ngIf="recentlyUpdatedOpen">
    <div class="row map-search-result pointer">
      <div class="col">
        <div
          *ngFor="let result of recentlyUpdatedJobs()"
          (click)="goToJobResult(result.data)"
          class="row"
        >
          <div class="col-10 text-left">
            <i
              class="fas fa-circle"
              [style.color]="
                jobService.getMapMarkerIconFillColor(result.data.job)
              "
            ></i>
            {{ result.data.site.id }} -
            {{ result.data.site.searchAddress }}
          </div>
          <div class="col text-right">
            {{ result.data.job.lastUpdated | date: "dd/MM/yy HH:mm:ss" }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="overflow-y: visible; height: 0;" *ngIf="filtersOpen">
    <div class="row map-search-result">
      <div class="col">
        <div class="row">
          <div class="col site-summary-header font-weight-bold text-center">
            Jobs
          </div>
        </div>

        <div class="row">
          <div class="col text-right">
            Installation
            <i
              class="fas fa-circle"
              [style.color]="jobService.getMarkerColor(jobTypes.Installation)"
            ></i>
          </div>
          <div class="col text-left">
            <input
              type="checkbox"
              class="form-check-input"
              (change)="jobTypeToggle(jobTypes.Installation, $event)"
              [checked]="jobTypeSelected(jobTypes.Installation)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Replacement Cover
            <i
              class="fas fa-circle"
              [style.color]="
                jobService.getMarkerColor(jobTypes.ReplacementCover)
              "
            ></i>
          </div>
          <div class="col text-left">
            <input
              type="checkbox"
              class="form-check-input"
              (change)="jobTypeToggle(jobTypes.ReplacementCover, $event)"
              [checked]="jobTypeSelected(jobTypes.ReplacementCover)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Delivery
            <i
              class="fas fa-circle"
              [style.color]="jobService.getMarkerColor(jobTypes.Delivery)"
            ></i>
          </div>
          <div class="col text-left">
            <input
              type="checkbox"
              class="form-check-input"
              (change)="jobTypeToggle(jobTypes.Delivery, $event)"
              [checked]="jobTypeSelected(jobTypes.Delivery)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Service
            <i
              class="fas fa-circle"
              [style.color]="jobService.getMarkerColor(jobTypes.Service)"
            ></i>
          </div>
          <div class="col text-left">
            <input
              type="checkbox"
              class="form-check-input"
              (change)="jobTypeToggle(jobTypes.Service, $event)"
              [checked]="jobTypeSelected(jobTypes.Service)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Repair
            <i
              class="fas fa-circle"
              [style.color]="jobService.getMarkerColor(jobTypes.Repair)"
            ></i>
          </div>
          <div class="col text-left">
            <input
              type="checkbox"
              class="form-check-input"
              (change)="jobTypeToggle(jobTypes.Repair, $event)"
              [checked]="jobTypeSelected(jobTypes.Repair)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Survey
            <i
              class="fas fa-circle"
              [style.color]="jobService.getMarkerColor(jobTypes.Survey)"
            ></i>
          </div>
          <div class="col text-left">
            <input
              type="checkbox"
              class="form-check-input"
              (change)="jobTypeToggle(jobTypes.Survey, $event)"
              [checked]="jobTypeSelected(jobTypes.Survey)"
            />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col site-summary-header font-weight-bold text-center">
            Vehicles
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Show
          </div>
          <div class="col text-left">
            <input
              type="checkbox"
              class="form-check-input"
              (change)="vehicleShowToggle($event)"
              [checked]="showVehicles"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            Only show moving
          </div>
          <div class="col text-left">
            <input
              type="checkbox"
              class="form-check-input"
              (change)="vehicleOnlyShowMovingToggle($event)"
              [checked]="showOnlyMovingVehicles"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="overflow-y: visible; height: 0;" *ngIf="showSearchResults">
    <div
      class="row map-search-result pointer"
      *ngIf="geoCodeResult && geoCodeResult.length > 0"
    >
      <div class="col">
        <div
          *ngFor="let result of geoCodeResult"
          (click)="goToGeocodeResult(result)"
        >
          <span class="font-weight-bold">Search Result: </span
          >{{ result.name }} -
          {{ result.formatted_address }}
        </div>
      </div>
    </div>

    <div
      class="row map-search-result pointer"
      *ngIf="searchResultVehicles && searchResultVehicles.length > 0"
    >
      <div class="col">
        <div
          *ngFor="let result of searchResultVehicles"
          (click)="goToVehicleResult(result)"
        >
          <img [src]="vehicleIcon(result)" width="32" height="32" />
          <span *ngIf="result.lastUpdate.driverName"
            >{{ result.lastUpdate.driverName }} - </span
          >{{ result.lastUpdate.assetRegistration
          }}<span *ngIf="result.lastUpdate.speed">
            - Travelling at {{ vehicleSpeed(result) }}mph</span
          >
        </div>
      </div>
    </div>

    <div
      class="row map-search-result pointer"
      *ngIf="searchResultJobs.length > 0"
    >
      <div class="col">
        <div
          *ngFor="let result of searchResultJobs"
          (click)="goToJobResult(result)"
        >
          <i
            class="fas fa-circle"
            [style.color]="jobService.getMapMarkerIconFillColor(result.job)"
          ></i>
          {{ result.site.id }} -
          {{ result.site.searchAddress }}
        </div>
      </div>
    </div>
  </div>
</div>

<agm-map
  [longitude]="mapLng"
  [latitude]="mapLat"
  [usePanning]="true"
  [zoom]="mapZoom"
  (boundsChange)="mapBoundsChanged($event)"
  (mapReady)="setMap($event)"
>
  <agm-marker
    *ngFor="let marker of searchMarkers"
    [latitude]="marker.lat"
    [longitude]="marker.lng"
  ></agm-marker>
  <agm-marker
    *ngFor="let result of getVehiclesFiltered()"
    [latitude]="result.lastUpdate.latitude"
    [longitude]="result.lastUpdate.longitude"
    (markerClick)="openInfoWindow(result)"
    [iconUrl]="{
      url: '' + vehicleIcon(result) + '',
      scaledSize: { width: 32, height: 32 },
      anchor: { x: 16, y: 16 }
    }"
    ><agm-info-window
      [latitude]="result.lastUpdate.latitude"
      [longitude]="result.lastUpdate.longitude"
      [isOpen]="isInfoWindowOpen(result)"
      (infoWindowClose)="onCloseInfoWindow(result)"
      ><div class="font-weight-bold">
        {{ result.lastUpdate.assetName }}
        ({{ result.lastUpdate.assetRegistration }})
      </div>
      <div *ngIf="result.lastUpdate.driverName">
        Driver: {{ result.lastUpdate.driverName }}
      </div>
      <div *ngIf="result.lastUpdate.speed">
        Speed: {{ vehicleSpeed(result) }}mph
      </div>
      <div *ngIf="!result.lastUpdate.speed">
        Not moving
      </div>

      <div>
        Last updated {{ result.lastUpdate.date | date: "dd/MM/yy HH:mm:ss" }}
      </div>
    </agm-info-window>
  </agm-marker>

  <agm-marker
    *ngFor="let result of mapVisibleJobs"
    [latitude]="result.data.site.addressLat"
    [longitude]="result.data.site.addressLng"
    [iconUrl]="result.marker"
    (markerClick)="openInfoWindow(result.data.job)"
    ><agm-info-window
      [latitude]="result.data.site.addressLat"
      [longitude]="result.data.site.addressLng"
      [isOpen]="isInfoWindowOpen(result.data.job)"
      (infoWindowClose)="onCloseInfoWindow(result.data.job)"
    >
      <h4 class="font-weight-bold text-center">
        {{ result.data.site.id }} - {{ result.data.site.name }}
      </h4>

      <div
        class="container-fluid alert alert-secondary"
        *ngFor="let jobResult of getAllJobsForSite(result.data.site)"
      >
        <div class="row">
          <div class="col-3"></div>
          <div class="col-6 text-center font-weight-bold">
            Job {{ jobResult.data.job.id }}
          </div>
          <div class="col-3 text-right">
            <i
              style="justify-self: end;"
              class="far fa-edit pointer"
              (click)="editJob(jobResult.data.job, jobResult.data.site)"
              ngbTooltip="Edit"
            ></i>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              [innerHTML]="
                jobService.nl2br(jobResult.data.job.descriptionWithNotes)
                  | noEscapeHtml
              "
            ></div>
          </div>
        </div>
      </div>
      <div></div></agm-info-window
  ></agm-marker>
</agm-map>
