<div class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          Name
        </div>
        <input class="form-control" type="text" formControlName="name" />
      </div>
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          Job Title
        </div>
        <input class="form-control" type="text" formControlName="jobTitle" />
      </div>
    </div>

    <div class="row"><div class="col">&nbsp;</div></div>

    <div class="row">
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          <div class="row">
            <div class="col"></div>
            <div class="col">Emails</div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!getFormArray('emails').length">
      <div class="col">
        No addresses
      </div>
    </div>

    <div
      class="container"
      formArrayName="addresses"
      *ngFor="let item of getFormArray('emails').controls; let i = index"
    >
      <div class="row" [formGroupName]="i">
        <div class="col-12">
          <input
            class="form-control"
            type="text"
            placeholder="E-mail address"
            formControlName="email"
          />
        </div>
      </div>
    </div>

    <div class="row"><div class="col">&nbsp;</div></div>

    <div class="row">
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          <div class="row">
            <div class="col"></div>
            <div class="col">Addresses</div>
            <div class="col">
              <i
                class="fas fa-plus pointer"
                (click)="addAddress()"
                ngbTooltip="Add"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!getFormArray('addresses').length">
      <div class="col">
        No addresses
      </div>
    </div>

    <div
      class="container"
      formArrayName="addresses"
      *ngFor="let item of getFormArray('addresses').controls; let i = index"
    >
      <div class="row" [formGroupName]="i">
        <div class="col-12">
          <input
            class="form-control"
            type="text"
            placeholder="Line 1"
            formControlName="address"
          />
        </div>
        <div class="col-12">
          <input
            class="form-control"
            type="text"
            placeholder="Line 2"
            formControlName="address2"
          />
        </div>
        <div class="col-12">
          <input
            class="form-control"
            type="text"
            placeholder="Line 3"
            formControlName="address3"
          />
        </div>
        <div class="col-12">
          <input
            class="form-control"
            type="text"
            placeholder="Town"
            formControlName="addressTown"
          />
        </div>
        <div class="col-12">
          <input
            class="form-control"
            type="text"
            placeholder="County"
            formControlName="addressCounty"
          />
        </div>
        <div class="col-12">
          <input
            class="form-control"
            type="text"
            placeholder="Postcode"
            formControlName="addressPCode"
          />
        </div>
      </div>
    </div>

    <div class="row"><div class="col">&nbsp;</div></div>

    <div class="row">
      <div class="col text-right">
        <button class="btn btn-success">Save</button>
      </div>
    </div>
  </form>
</div>
