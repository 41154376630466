<mat-toolbar class="nav-container">
  <div
    routerLink="../summary"
    class="nav-element"
    [ngClass]="{ 'nav-selected': current === 'summary' }"
  >
    Summary
  </div>
  <div
    routerLink="../images"
    class="nav-element"
    [ngClass]="{ 'nav-selected': current === 'images' }"
  >
    Images
  </div>
  <div
    routerLink="../jobs"
    class="nav-element"
    [ngClass]="{ 'nav-selected': current === 'jobs' }"
  >
    Jobs
  </div>
</mat-toolbar>
