<div *ngIf="updated" class="alert alert-warning text-center" role="alert">
    This site has been modified and the information is out of date.<br />You must
    re-open this form to carry out any changes.
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col font-weight-bold text-center site-summary-header">
                        Add/Edit Setting
                    </div>
                </div>

                <div class="row">
                    <div class="col">&nbsp;</div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="font-weight-bold text-center site-summary-header">
                            Key
                        </div>
                        <input type="text" formControlName="id" class="form-control" />
                    </div>
                    <div class="col">
                        <div class="font-weight-bold text-center site-summary-header">
                            Type
                        </div>
                        <select formControlName="type" class="form-control">
                            <option value="0">String Array</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="col">&nbsp;</div>
                </div>

                <div class="row" *ngIf="isSingleValueSetting()">
                    <div class="col">
                        <div class="font-weight-bold text-center site-summary-header">
                            Value
                        </div>
                        <input type="text" formControlName="value" class="form-control" />
                    </div>
                </div>

                <span *ngIf="!isSingleValueSetting()">
                    <div class="row">
                        <div class="col site-summary-header">
                        </div>
                        <div class="col site-summary-header">
                            <div class="font-weight-bold text-center">
                                Values
                            </div>
                        </div>
                        <div class="col text-right site-summary-header"><i class="fas fa-plus pointer"
                                (click)="addValue()" ngbTooltip="Add"></i></div>
                    </div>
                    <div class="row" *ngFor="let item of getValuesArray().controls; let i = index">
                        <div class="col-10">
                            <div formArrayName="values">
                                <input type="text" [formControlName]="i" class="form-control" />
                            </div>
                        </div>
                        <div class="col-2">
                            <button type="button" class="btn btn-primary" (click)="removeValue(i)">Delete</button>
                        </div>
                    </div>
                </span>

                <div class="row">
                    <div class="col">&nbsp;</div>
                </div>

                <div class="row">
                    <div class="col text-right">
                        <button type="submit" class="btn btn-success" [disabled]="!form.valid || updated">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>