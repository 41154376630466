/// <reference types="@types/googlemaps" />
import { Component, OnInit, OnDestroy } from '@angular/core';
import { JobSearchResponse } from 'src/app/viewmodels/generated/job-search-response';
import { HttpClient } from '@angular/common/http';
import { ApiEndpoint } from 'src/app/enum/apiendpoints.enum';
import { JobSearchRequest } from 'src/app/viewmodels/generated/job-search-request';
import { JobStatus } from 'src/app/viewmodels/generated/job-status';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { JobService } from 'src/app/service/job.service';
import { MapsAPILoader, LatLngBounds, LatLng } from '@agm/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobState } from 'src/app/viewmodels/generated/job-state';
import { Site } from 'src/app/viewmodels/generated/site';
import { EditJobComponent } from '../edit-job/edit-job.component';
import { VehicleState } from 'src/app/viewmodels/generated/vehicle-state';
import { SignalRService } from 'src/app/signal-r.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JobType } from 'src/app/viewmodels/generated/job-type';
import { VisibleMapMarker as JobSearchResponseWithMapMarker } from './visibleMapMarker';
import sort from 'fast-sort';

interface SearchMarker {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  pendingJobs: JobSearchResponseWithMapMarker[] = [];
  vehicles: VehicleState[] = [];
  mapVisibleJobs: JobSearchResponseWithMapMarker[] = [];
  mapVisibleVehicles: VehicleState[] = [];

  searchResultJobs: JobSearchResponse[] = [];
  geoCodeResult: google.maps.places.PlaceResult[] = [];
  searchTextControl = new FormControl();
  searchText$: Subscription;
  placesService: google.maps.places.PlacesService;

  filtersOpen: boolean;
  recentlyUpdatedOpen: boolean;

  mapLng = -3.436;
  mapLat = 55.3781;
  mapZoom = 8;
  mapBoundary: LatLngBounds;

  showSearchResults = true;
  currentInfoWindow: JobState | VehicleState;

  map: google.maps.Map;
  searchMarkers: SearchMarker[] = [];
  searchResultVehicles: VehicleState[];
  route$: Subscription;

  jobTypes = JobType;

  // By default we show everything
  showVehicles = true;
  showOnlyMovingVehicles = false;
  visibleJobTypes: JobType[] = [
    JobType.Unknown,
    JobType.Delivery,
    JobType.Installation,
    JobType.ReplacementCover,
    JobType.Repair,
    JobType.Service,
    JobType.Survey
  ];

  vehicleUpdate$: Subscription;
  jobUpdated$: Subscription;

  constructor(
    private http: HttpClient,
    public jobService: JobService,
    private mapsAPILoader: MapsAPILoader,
    private modalService: NgbModal,
    private signalr: SignalRService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const self = this;
    this.route$ = this.route.queryParams.subscribe(params => {
      if (params.showVehicles) {
        self.showVehicles = params.showVehicles === 'true';
      }
      if (params.showOnlyMovingVehicles) {
        self.showOnlyMovingVehicles = params.showOnlyMovingVehicles === 'true';
      }
      if (params.jobtype) {
        self.visibleJobTypes = [];
        params.jobtype.forEach(val => {
          self.visibleJobTypes.push(+val);
        });
        // Job types have changed so we fetch pending jobs again
        self.fetchPendingJobs();
      }
    });

    this.fetchPendingJobs();
    this.fetchVehicles();

    this.searchText$ = this.searchTextControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(val => {
        this.onTextSearchUpdate(val);
      });

    this.mapsAPILoader.load().then(() => {
      this.placesService = new google.maps.places.PlacesService(
        document.createElement('div')
      );
    });

    this.jobUpdated$ = this.signalr.jobUpdatedObserver.subscribe(() =>
      this.fetchPendingJobs()
    );
    this.vehicleUpdate$ = this.signalr.vehicleBatchUpdate.subscribe(() =>
      this.fetchVehicles()
    );
  }

  ngOnDestroy() {
    if (this.searchText$) {
      this.searchText$.unsubscribe();
    }
    if (this.jobUpdated$) {
      this.jobUpdated$.unsubscribe();
    }
    if (this.vehicleUpdate$) {
      this.vehicleUpdate$.unsubscribe();
    }
  }

  fetchVehicles() {
    this.http.get<VehicleState[]>(ApiEndpoint.vehicles.all).subscribe(res => {
      this.vehicles = res;
      this.calculateVisibleMapItems();
    });
  }

  fetchPendingJobs() {
    const request: JobSearchRequest = {
      query: '',
      types: this.visibleJobTypes,
      statuses: [JobStatus.Pending],
      from: null,
      to: null,
      onlyUnassigned: false
    };

    this.http
      .post<JobSearchResponse[]>(ApiEndpoint.job.query, request)
      .subscribe(res => {
        this.pendingJobs = this.createVisibleMapMarkers(res);
        this.calculateVisibleMapItems();
      });
  }

  onTextSearchUpdate(val: string) {
    const request: JobSearchRequest = {
      query: val,
      types: this.visibleJobTypes,
      statuses: [JobStatus.Pending],
      from: null,
      to: null,
      onlyUnassigned: false
    };

    this.http
      .post<JobSearchResponse[]>(ApiEndpoint.job.query, request)
      .subscribe(res => {
        this.searchResultJobs = res;
      });

    this.searchResultVehicles = this.vehicles.filter(
      a =>
        this.shouldShowVehicle(a) &&
        (a.lastUpdate.assetRegistration
          .toLowerCase()
          .indexOf(val.toLowerCase()) !== -1 ||
          (a.lastUpdate.driverName &&
            a.lastUpdate.driverName.toLowerCase().indexOf(val.toLowerCase()) !==
              -1))
    );

    const placesRequest: google.maps.places.TextSearchRequest = {
      query: val
    };

    setTimeout(() => {
      this.placesService.textSearch(placesRequest, res => {
        setTimeout(() => (this.geoCodeResult = res));
      });
    });
  }

  goToJobResult(response: JobSearchResponse) {
    this.map.panTo({
      lat: response.site.addressLat,
      lng: response.site.addressLng
    });
    this.map.setZoom(10);
    this.clearSearchMarker(); // Clear any address search markers
    this.openInfoWindow(response.job);
    this.recentlyUpdatedOpen = false;
  }

  goToVehicleResult(vehicle: VehicleState) {
    this.map.panTo({
      lat: vehicle.lastUpdate.latitude,
      lng: vehicle.lastUpdate.longitude
    });

    this.map.setZoom(10);
    this.clearSearchMarker();
    this.openInfoWindow(vehicle);
  }

  goToGeocodeResult(place: google.maps.places.PlaceResult) {
    this.map.panTo(place.geometry.location);
    this.map.setZoom(10);

    this.clearSearchMarker();
    const marker: SearchMarker = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    };
    this.searchMarkers.push(marker);
  }

  clearSearchMarker() {
    this.searchMarkers = [];
  }

  setMap(map: google.maps.Map) {
    this.map = map;
  }

  addSearchResults() {
    this.showSearchResults = true;
  }

  removeSearchResults() {
    setTimeout(() => {
      this.showSearchResults = false;
    }, 500);
  }

  openInfoWindow(val: JobState | VehicleState) {
    this.currentInfoWindow = val;
  }

  onCloseInfoWindow(val: JobState | VehicleState) {
    if (this.currentInfoWindow === val) {
      this.currentInfoWindow = null;
    }
  }

  isInfoWindowOpen(val: JobState | VehicleState) {
    return this.currentInfoWindow && this.currentInfoWindow.id === val.id;
  }

  editJob(job: JobState, site: Site) {
    const modalRef = this.modalService.open(EditJobComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.site = site;
    modalRef.componentInstance.job = job;
  }

  getAllJobsForSite(site: Site) {
    return this.pendingJobs.filter(a => a.data.job.siteId === site.id);
  }

  vehicleSpeed(vehicle: VehicleState) {
    return Math.round(vehicle.lastUpdate.speed / 1.609);
  }

  vehicleIcon(vehicle: VehicleState) {
    let icon = 'https://storage.forgeleisure.host/Vehicles/default.png';

    if (vehicle.mapIcon) {
      icon = vehicle.mapIcon;
    }

    return ApiEndpoint.image.rotate(icon, vehicle.lastUpdate.heading - 90);
  }

  filterToggle() {
    this.filtersOpen = !this.filtersOpen;
  }

  jobTypeToggle(val: JobType, event) {
    const newVal = event.currentTarget.checked;

    let filter = this.visibleJobTypes;

    if (newVal) {
      this.visibleJobTypes.push(val);
    } else {
      filter = this.visibleJobTypes.filter(a => a !== val);
    }

    this.router.navigate([], {
      queryParams: { jobtype: filter },
      queryParamsHandling: 'merge'
    });
  }

  jobTypeSelected(val: JobType) {
    return this.visibleJobTypes.find(a => a === val);
  }

  vehicleShowToggle(event) {
    const newVal = event.currentTarget.checked;
    this.router.navigate([], {
      queryParams: { showVehicles: newVal },
      queryParamsHandling: 'merge'
    });
  }

  vehicleOnlyShowMovingToggle(event) {
    const newVal = event.currentTarget.checked;
    this.router.navigate([], {
      queryParams: { showOnlyMovingVehicles: newVal },
      queryParamsHandling: 'merge'
    });
  }

  getVehiclesFiltered() {
    return this.mapVisibleVehicles.filter(a => this.shouldShowVehicle(a));
  }

  shouldShowVehicle(val: VehicleState) {
    if (!this.showVehicles) {
      return false;
    }
    if (this.showOnlyMovingVehicles && !val.lastUpdate.speed) {
      return false;
    }
    return true;
  }

  recentlyUpdatedToggle() {
    this.recentlyUpdatedOpen = !this.recentlyUpdatedOpen;
  }

  recentlyUpdatedJobs(): JobSearchResponseWithMapMarker[] {
    return sort(this.pendingJobs).desc(a => a.data.job.lastUpdated);
  }

  mapBoundsChanged(bounds: LatLngBounds) {
    this.mapBoundary = bounds;
    this.calculateVisibleMapItems();
  }

  calculateVisibleMapItems() {
    // If we have no map boundary, for safety, we display everything
    if (!this.mapBoundary) {
      this.mapVisibleJobs = this.pendingJobs;
      this.mapVisibleVehicles = this.mapVisibleVehicles;
    } else {
      this.mapVisibleJobs = this.pendingJobs.filter(job =>
        this.mapBoundary.contains(
          (new google.maps.LatLng(
            job.data.site.addressLat,
            job.data.site.addressLng
          ) as unknown) as LatLng
        )
      );
      this.mapVisibleVehicles = this.vehicles.filter(veh =>
        this.mapBoundary.contains(
          (new google.maps.LatLng(
            veh.lastUpdate.latitude,
            veh.lastUpdate.longitude
          ) as unknown) as LatLng
        )
      );
      console.log(
        `Boundaries changed: visible jobs ${this.mapVisibleJobs.length} vehicles ${this.mapVisibleVehicles.length}`
      );
    }

    this.mapVisibleJobs = this.mapVisibleJobs.filter(a => {
      const allJobsForSite = this.pendingJobs.filter(
        b => b.data.site.id === a.data.site.id
      );

      // No duplicates
      if (allJobsForSite.length === 1) {
        return true;
      }

      // If duplicates, only display the newest
      const maxJobIdForSite = Math.max(
        ...allJobsForSite.map(c => c.data.job.id)
      );
      return a.data.job.id === maxJobIdForSite;
    });

    console.log(
      `Visible jobs after de-duplication ${this.mapVisibleJobs.length}`
    );
  }

  createVisibleMapMarkers(
    results: JobSearchResponse[]
  ): JobSearchResponseWithMapMarker[] {
    return results.map(result => {
      return {
        data: result,
        marker: this.getIconForJob(result)
      } as JobSearchResponseWithMapMarker;
    });
  }

  getIconForJob(result: JobSearchResponse) {
    return {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 3,
      fillColor: this.jobService.getMapMarkerIconStrokeColor(result.job),
      fillOpacity: 0.9,
      strokeColor: this.jobService.getMapMarkerIconStrokeColor(result.job)
    };
  }
}
