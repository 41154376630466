import { environment } from "src/environments/environment";

export const ApiEndpoint = {
  site: {
    get: (id) => environment.apiBase + `site/${id}`,
    put: (id) => environment.apiBase + `site/${id}`,
    getjobs: environment.apiBase + "site/getjobs",
    search: environment.apiBase + "site/search",
    summary: environment.apiBase + "site/summary",
    updateAddress: environment.apiBase + "site/updateaddress",
    updateSystem: environment.apiBase + "site/updatesystem",
    deleteSystem: environment.apiBase + "site/deletesystem",
    uploadImage: environment.apiBase + "site/uploadimage",
    putContacts: environment.apiBase + "site/updatecontacts",
    gallery: environment.apiBase + "site/gallery",
  },
  upload: {
    put: (id) => environment.apiBase + `upload/${id}`,
  },
  job: {
    query: environment.apiBase + "job/q",
    createJob: environment.apiBase + "job",
    get: (jobid) => environment.apiBase + `job/${jobid}`,
    put: (jobid) => environment.apiBase + `job/${jobid}`,
  },
  admin: {
    elasticSync: environment.apiBase + "admin/elasticsync",
  },
  vehicles: {
    all: environment.apiBase + "vehicle/all",
  },
  user: {
    initialize: environment.apiBase + "user/initialize",
    getByClaim: environment.apiBase + "user/getbyclaim",
  },
  image: {
    rotate: (source, rot) =>
      environment.apiBase +
      `image/modify?source=${encodeURI(source)}&rot=${rot}`,
    resize: (source, width, height) =>
      environment.apiBase +
      `image/modify?source=${encodeURI(
        source
      )}&width=${width}&height=${height}`,
    resizeAndRotate: (source, width, height, rot) =>
      environment.apiBase +
      `image/modify?source=${encodeURI(
        source
      )}&width=${width}&height=${height}&rot=${rot}`,
  },
  signalR: environment.apiBase + "notifications",
  googleMaps: {
    geoCode: "https://maps.googleapis.com/maps/api/geocode/json",
  },
  printQueue: {
    get: environment.apiBase + "printqueue/get",
    put: (id) => environment.apiBase + `printqueue/${id}`,
  },
  config: {
    get: (id) => environment.apiBase + `configuration/${id}`,
    put: (id) => environment.apiBase + `configuration/${id}`,
    getall: environment.apiBase + "configuration/all",
  }
};
