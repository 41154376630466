import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warning-areyousure',
  templateUrl: './warning-areyousure.component.html',
  styleUrls: ['./warning-areyousure.component.scss']
})
export class WarningAreyousureComponent implements OnInit {
  @Input('message') message: string;
  @Input('title') title: string;
  constructor(private modal: NgbActiveModal) {}

  ngOnInit() {}

  onOk() {
    this.modal.close(true);
  }

  onCancel() {
    this.modal.close(false);
  }
}
