<app-site-navbar current="summary"></app-site-navbar>

<div class="container" *ngIf="!siteSummary">
  <div class="row">
    <div class="col d-flex justify-content-center">
      <mat-spinner clas="text-center"></mat-spinner>
    </div>
  </div>
</div>

<div class="container" *ngIf="siteSummary">
  <div class="row justify-content-md-center site-summary">
    <div class="col mobile-break">
      <div class="row">
        <div class="col font-weight-bold text-center site-summary-header">
          <div class="row">
            <div class="col"></div>
            <div class="col text-centre">Address</div>
            <div class="col text-right">
              <i style="justify-self: end;" class="far fa-edit pointer" (click)="editAddress()" ngbTooltip="Edit"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngFor="let addresspart of getAddressParts()">
        <div class="col text-center">{{ addresspart }}</div>
      </div>
      <div class="row"></div>
    </div>
    <div class="col mobile-break">
      <div class="row">
        <div class="col font-weight-bold text-center site-summary-header">
          Location
        </div>
      </div>
      <div class="row">
        <div class="col text-center" *ngIf="
            !this.siteSummary.site.addressLat &&
            !this.siteSummary.site.addressLng
          ">
          Unknown. Edit address to set position.
        </div>
        <div class="col site-summary-map-container" *ngIf="
            this.siteSummary.site.addressLat && this.siteSummary.site.addressLng
          ">
          <agm-map [latitude]="siteSummary.site.addressLat" [longitude]="siteSummary.site.addressLng">
            <agm-marker [latitude]="siteSummary.site.addressLat" [longitude]="siteSummary.site.addressLng">
            </agm-marker>
          </agm-map>
        </div>
      </div>
    </div>
    <div class="col mobile-break">
      <div class="row">
        <div class="col font-weight-bold text-center site-summary-header">
          <div class="row">
            <div class="col"></div>
            <div class="col">Systems</div>
            <div class="col text-right">
              <i class="fas fa-plus pointer" (click)="openSystemCreate()" ngbTooltip="Create system"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 text-center font-weight-bold">Name</div>
        <div class="col-4 text-center font-weight-bold">Type</div>
        <div class="col-4 text-center font-weight-bold">Ours</div>
      </div>
      <div class="row" *ngFor="let system of siteSummary.site.systems" (click)="editSystem(system)">
        <div class="col-4 text-center pointer">{{ system.name }}</div>
        <div class="col-4 text-center pointer">
          {{ getSystemType(system) }}
        </div>
        <div class="col-4 text-center pointer">
          {{ getSystemIsOurs(system) }}
        </div>
        <div class="col-12 text-left pointer" *ngIf="getSystemNotes(system) != null"
          [innerHTML]="getSystemNotes(system) | noEscapeHtml"></div>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center site-summary">
    <div class="col mobile-break">
      <div class="row">
        <div class="col font-weight-bold text-center site-summary-header">
          <div class="row">
            <div class="col"></div>
            <div class="col">Recent Jobs</div>
            <div class="col text-right">
              <i class="fas fa-plus pointer" (click)="addJob()" ngbTooltip="Create job"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-2 text-center font-weight-bold">ID</div>
        <div class="col text-center font-weight-bold">Description</div>
        <div class="col-2 text-center font-weight-bold">Status</div>
        <div class="col-3 text-center font-weight-bold">Last Update</div>
      </div>
      <div class="row" *ngFor="let job of siteSummary.jobs" (click)="editJob(job)">
        <div class="col-2 text-center pointer">{{ job.id }}</div>
        <div class="col text-left pointer" [innerHTML]="getJobDescriptionWithNotes(job) | noEscapeHtml"></div>
        <div class="col-2 text-center pointer">
          {{ jobStatuses[job.status] }}
        </div>
        <div class="col-3 text-center pointer">
          {{ job.lastUpdated | date: "dd/MM/yy HH:mm:ss" }}
        </div>
      </div>
    </div>

    <div class="col mobile-break">
      <div class="row">
        <div class="col font-weight-bold text-center site-summary-header">
          <div class="row">
            <div class="col"></div>
            <div class="col font-weight-bold text-center">
              Maintenance
            </div>
            <div class="col text-right">
              <i style="justify-self: end;" class="far fa-edit pointer" (click)="editMaintenance()" ngbTooltip="Edit">
              </i>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!siteSummary.site.serviceCost?.attendance && !siteSummary.serviceCost?.attendance">
        <div class="col text-centre">
          Unknown costs (possibly due to location). You can manually enter costs
          by pressing the edit button.
        </div>
      </div>
      <div class="row" *ngIf="siteSummary.site.serviceCost?.attendance || siteSummary.serviceCost?.attendance">
        <div class="col text-centre">
          <button class="btn btn-primary" (click)="sendServiceReminder();">Send Reminder</button>
        </div>
      </div>
      <div class="row" *ngIf="siteSummary.site.serviceCost?.firstMotorised
        ">
        <div class="col text-right">
          First motorised system:
        </div>
        <div class="col text-left">
          {{ siteSummary.site.serviceCost.firstMotorised | currencyOrUnknown: "GBP" }}
        </div>
      </div>
      <div class="row" *ngIf="siteSummary.site.serviceCost?.firstManual
        ">
        <div class="col text-right">
          First manual system:
        </div>
        <div class="col text-left">
          {{ siteSummary.site.serviceCost?.firstManual | currencyOrUnknown: "GBP" }}
        </div>
      </div>

      <div class="row">
        <div class="col text-right">
          Motorised system:
        </div>
        <div class="col text-left">
          {{ siteSummary.site.serviceCost?.motorised || siteSummary.serviceCost?.motorised | currencyOrUnknown: "GBP" }}
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          Manual system:
        </div>
        <div class="col text-left">
          {{ siteSummary.site.serviceCost?.manual || siteSummary.serviceCost?.manual | currencyOrUnknown: "GBP" }}
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          Attendance:
        </div>
        <div class="col text-left">
          {{ siteSummary.site.serviceCost?.attendance || siteSummary.serviceCost?.attendance | currencyOrUnknown: "GBP" }}
        </div>
      </div>

      <div class="row" *ngIf="siteSummary.serviceReminders.length">
        <div class="col font-weight-bold text-center site-summary-header">
          Reminders
        </div>
      </div>
      <div class="row" *ngIf="siteSummary.serviceReminders.length">
        <div class="col font-weight-bold text-center">
          Type
        </div>
        <div class="col font-weight-bold text-center">
          Date
        </div>
      </div>
      <div class="row pointer" *ngFor="let result of siteSummary.serviceReminders"
        (click)="openServiceReminderView(result)">
        <div class="col text-center">
          {{ getServiceReminderType(result) }}
        </div>
        <div class="col text-center">
          {{ result.created | date: "dd/MM/yy HH:mm:ss" }}
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-md-center site-summary">
    <div class="col mobile-break">
      <div class="row">
        <div class="col font-weight-bold text-center site-summary-header">
          Recent Images
        </div>
      </div>
      <div class="row">
        <div class="col-3 img site-summary-img-container d-flex justify-content-center"
          *ngFor="let image of siteSummary.recentImages">
          <img [ngStyle]="{
              transform: 'rotate(' + getImageRotation(image) + 'deg)'
            }" [src]="image.mediaLink" class="img pointer" (click)="openImageGalleryAt(image)" width="128"
            height="128" />
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <ngx-file-drop (onFileDrop)="imageFileDropped($event)" [showBrowseBtn]="true" accept="bmp,png,jpg,jpeg,gif"
            browseBtnLabel="browse" dropZoneClassName="img-uploader-dropzone" contentClassName="img-uploader-content"
            browseBtnClassName="img-uploader-button">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div>Drop files to upload or &nbsp;
                <button type="button" class="btn btn-primary" (click)="openFileSelector()">Browse</button>&nbsp;
              </div>
            </ng-template>
          </ngx-file-drop>
        </div>
      </div>
      <div class="row" *ngFor="let upload of getFilteredImageUploads()">
        <div class="col-12 text-center">
          {{ upload.filename }} ({{ upload.uploadLoadedKB }} /
          {{ upload.uploadTotalKB }}KB)
        </div>
        <div class="col-12">
          <mat-progress-bar [value]="upload.uploadPercentage"></mat-progress-bar>
        </div>
      </div>
    </div>

    <div class="col mobile-break">
      <div class="row">
        <div class="col font-weight-bold text-center site-summary-header">
          <div class="row">
            <div class="col"></div>
            <div class="col">
              Contacts
            </div>
            <div class="col text-right">
              <i class="fas fa-plus pointer" ngbTooltip="Create contact" (click)="createContact()"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col">
          <div class="row" *ngFor="let result of getContacts()">
            <div class="col"></div>
            <div class="col">
              {{ getContactName(result) }}
            </div>
            <div class="col text-right">
              <i style="justify-self: end;" class="far fa-edit pointer" (click)="editContact(result)" ngbTooltip="Edit">
              </i>
              <i class="far fa-trash-alt" (click)="deleteContact(result)" ngbTooltip="Delete"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>