import { Component, OnInit, Input } from '@angular/core';
import { Site } from 'src/app/viewmodels/generated/site';
import { Contact } from 'src/app/viewmodels/generated/contact';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JobService } from 'src/app/service/job.service';
import { SignalRService } from 'src/app/signal-r.service';
import { HttpClient } from '@angular/common/http';
import { ApiEndpoint } from 'src/app/enum/apiendpoints.enum';
import { UpdateSiteContactsViewModel } from 'src/app/viewmodels/generated/update-site-contacts-view-model';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss']
})
export class EditContactComponent implements OnInit {
  @Input('site') site: Site;
  @Input('contact') contact: Contact;

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private modal: NgbActiveModal
  ) {}

  ngOnInit() {
    if (!this.contact) {
      this.contact = {
        name: '',
        jobTitle: '',
        telephoneNumbers: [],
        emails: [],
        addresses: [],
        preferred: false,
        deleted: false,
        deletionReason: ''
      };
    }

    this.form = this.formBuilder.group({
      name: { value: this.contact.name, disabled: false },
      jobTitle: { value: this.contact.jobTitle, disabled: false },
      preferred: { value: this.contact.preferred, disabled: false },
      deleted: { value: this.contact.deleted, disabled: false },
      deletionReason: { value: this.contact.deletionReason, disabled: false },
      telephoneNumbers: this.formBuilder.array([]),
      emails: this.formBuilder.array([]),
      addresses: this.formBuilder.array([])
    });

    this.contact.telephoneNumbers.forEach(val => {
      const array = this.form.get('telephoneNumbers') as FormArray;
      array.push(
        this.formBuilder.group({
          number: { value: val.number, disabled: false },
          type: { value: val.type, disabled: false },
          preferred: { value: val.preferred, disabled: false },
          deleted: { value: val.deleted, disabled: false },
          deletionReason: { value: val.deletionReason, disabled: false }
        })
      );
    });

    this.contact.emails.forEach(val => {
      const array = this.form.get('emails') as FormArray;
      array.push(
        this.formBuilder.group({
          email: { value: val.email, disabled: true },
          preferred: { value: val.preferred, disabled: true },
          deleted: { value: val.deleted, disabled: true },
          deletionReason: { value: val.deletionReason, disabled: true }
        })
      );
    });

    this.contact.addresses.forEach(val => {
      const array = this.form.get('addresses') as FormArray;
      array.push(
        this.formBuilder.group({
          address: { value: val.address, disabled: false },
          address2: { value: val.address2, disabled: false },
          address3: { value: val.address3, disabled: false },
          addressTown: { value: val.addressTown, disabled: false },
          addressCounty: { value: val.addressCounty, disabled: false },
          addressPCode: { value: val.addressPCode, disabled: false },
          preferred: { value: val.preferred, disabled: false },
          deleted: { value: val.deleted, disabled: false },
          deletionReason: { value: val.deletionReason, disabled: false }
        })
      );
    });
  }

  addEmail() {
    const array = this.form.get('emails') as FormArray;
    array.push(
      this.formBuilder.group({
        email: { value: '', disabled: true },
        preferred: { value: false, disabled: true },
        deleted: { value: false, disabled: true },
        deletionReason: { value: '', disabled: true }
      })
    );
  }

  addAddress() {
    const array = this.form.get('addresses') as FormArray;
    array.push(
      this.formBuilder.group({
        address: { value: '', disabled: false },
        address2: { value: '', disabled: false },
        address3: { value: '', disabled: false },
        addressTown: { value: '', disabled: false },
        addressCounty: { value: '', disabled: false },
        addressPCode: { value: '', disabled: false },
        preferred: { value: '', disabled: false },
        deleted: { value: false, disabled: false },
        deletionReason: { value: '', disabled: false }
      })
    );
  }

  addTelephone() {
    const array = this.form.get('telephoneNumbers') as FormArray;
    array.push(
      this.formBuilder.group({
        number: { value: '', disabled: false },
        type: { value: '', disabled: false },
        preferred: { value: '', disabled: false },
        deleted: { value: false, disabled: false },
        deletionReason: { value: '', disabled: false }
      })
    );
  }

  getFormArray(key: string) {
    return this.form.get(key) as FormArray;
  }

  onSubmit() {
    const contact = { ...this.contact, ...this.form.value };

    const currentIndex = this.site.contacts.indexOf(this.contact);

    if (currentIndex === -1) {
      this.site.contacts.push(contact);
    } else {
      this.site.contacts[currentIndex] = contact;
    }

    const request: UpdateSiteContactsViewModel = {
      siteId: this.site.id,
      contacts: this.site.contacts
    };

    this.http.put(ApiEndpoint.site.putContacts, request).subscribe(
      () => {
        this.toastr.success('Contacts updated');
        this.modal.close();
      },
      () => {
        this.toastr.error('Error updating contacts');
      }
    );
  }
}
