import { ApiEndpoint } from "src/app/enum/apiendpoints.enum";
import { HttpClient } from "@angular/common/http";
import { Injectable, SystemJsNgModuleLoader } from "@angular/core";
import { JobState } from "../viewmodels/generated/job-state";
import { Site } from "../viewmodels/generated/site";
import { JobType } from "../viewmodels/generated/job-type";

@Injectable({
  providedIn: "root",
})
export class JobService {
  constructor(private http: HttpClient) {}

  get(jobId: number) {
    return this.http.get<JobState>(ApiEndpoint.job.get(jobId));
  }

  create(job: JobState) {
    return this.http.post(ApiEndpoint.job.createJob, job);
  }

  update(job: JobState) {
    return this.http.put(ApiEndpoint.job.put(job.id), job);
  }

  nl2br(val: string) {
    return val.replace(/\n/gim, "<br />");
  }

  getFormattedDescription(job: JobState) {
    let ret = "";

    job.entries.forEach((entry) => {
      if (entry.description) {
        ret += `<div class='job-description'>${this.nl2br(
          entry.description
        )}</div>`;
      }

      if (entry.notes) {
        ret += `<div class='job-notes'>${this.nl2br(entry.notes)}</div>`;
      }
    });

    return ret;
  }

  generateDescription(job: JobState, site: Site, withnotes: boolean = false) {
    if (job.entries.length === 0) {
      return "";
    }
    let result = "";

    job.entries.forEach((entry) => {
      if (!entry.description) {
        return;
      }

      if (result.length > 0) {
        result += "\n";
      }

      if (+entry.systemId) {
        const system = site.systems.find((a) => a.id === +entry.systemId);

        if (system) {
          result += `${system.name}: `;
        }
      }

      result += entry.description;

      if (withnotes && entry.notes) {
        result += ` (${entry.notes})`;
      }
    });

    return result;
  }

  getJobType(job: JobState) {
    if (!job || job.entries.length === 0 || !job.entries[0].type) {
      return "Unknown";
    }

    switch (job.entries[0].type) {
      case JobType.Service:
        return "Service";
      case JobType.Survey:
        return "Survey";
      case JobType.Installation:
        return "Installation";
      case JobType.ReplacementCover:
        return "Replacement Cover";
      case JobType.Repair:
        return "Repair";
      default:
        return "Unknown";
    }
  }

  getMapMarkerIconRadius(job: JobState, map: google.maps.Map) {
    const p = Math.pow(2, 20 - map.getZoom());
    return p * 1128.49722 * 0.0004;
  }
  getMapMarkerIconFillOpacity(job: JobState) {
    return 1;
  }
  getMapMarkerIconStrokeOpacity(job: JobState) {
    return 1;
  }

  getMapMarkerIconStrokeColor(job: JobState) {
    return this.getMapMarkerIconFillColor(job);
  }

  getMapMarkerIconFillColor(job: JobState) {
    if (job && job.entries.length > 0) {
      return this.getMarkerColor(job.entries[0].type);
    }
    return "#000000";
  }

  getMarkerColor(type: JobType) {
    switch (type) {
      case JobType.Repair:
        return "#ffff00";
      case JobType.Installation:
        return "#ff0000";
      case JobType.ReplacementCover:
        return "#aa0000";
      case JobType.Survey:
        return "#e00bff";
      case JobType.Service:
        return "#0066ff";
      case JobType.Delivery:
        return "#00ff0a";
      default:
        return "#000000";
    }
  }
}
