<div *ngIf="authService.isLoggedIn()">
  <img *ngIf="getPhoto() !== null" [src]="getPhoto()" height="64" />
  <span [matMenuTriggerFor]="appMenu"> {{ getDisplayName() }}</span>
  <mat-menu #appMenu="matMenu">
    <button mat-menu-item (click)="clickLogout()">Logout</button>
  </mat-menu>
  &nbsp;&nbsp;&nbsp;&nbsp;
  <i
    class="fas fa-print"
    [matBadge]="printQueueService.getQueueLength()"
    matBadgeColor="accent"
    [matBadgeHidden]="printQueueService.getQueueLength() === 0"
    ngbTooltip="Print Queue"
    placement="left"
    routerLink="/printQueue"
  ></i>
</div>
