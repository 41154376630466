<div class="container">
  <div class="form-group">
    <label for="searchText">Search</label>
    <input
      type="text"
      id="searchText"
      [formControl]="searchTextControl"
      class="form-control"
    />
  </div>
  <span *ngIf="searchResults">
    <div class="row" *ngIf="searchResults.length == 0">
      <div class="col font-weight-bold text-center">No results found</div>
    </div>
    <div class="row" *ngIf="searchResults.length > 0">
      <div class="col-1 font-weight-bold">ID</div>
      <div class="col-11 font-weight-bold">Site</div>
    </div>
    <div
      class="row pointer"
      *ngFor="let result of searchResults"
      [routerLink]="[result.siteId, 'summary']"
    >
      <div class="col-1">{{ result.siteId }}</div>
      <div
        class="col-11"
        [innerHTML]="result.highlightedSearchString | noEscapeHtml"
      ></div>
    </div>
  </span>
</div>
