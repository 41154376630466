import { SiteUpdateAddressRequestViewModel } from '../../viewmodels/generated/site-update-address-request-view-model';
import { ApiEndpoint } from './../../enum/apiendpoints.enum';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteSummaryViewModel } from 'src/app/viewmodels/generated/site-summary-view-model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { SignalRService } from 'src/app/signal-r.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-site-address',
  templateUrl: './edit-site-address.component.html',
  styleUrls: ['./edit-site-address.component.scss']
})
export class EditSiteAddressComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private modal: NgbActiveModal,
    private signalr: SignalRService
  ) { }

  @Input() siteSummary: SiteSummaryViewModel;

  editAddress: FormGroup;
  addressUpdate$: Subscription;
  showEditedElsewhereWarning: boolean;

  ngOnInit() {
    this.addressUpdate$ = this.signalr.siteAddressUpdateObserver.subscribe(
      args => {
        if (this.siteSummary.site.id === +args) {
          this.editAddress.disable();
          this.showEditedElsewhereWarning = true;
        }
      }
    );

    this.editAddress = this.formBuilder.group({
      name: {
        value: this.siteSummary.site.name,
        disabled: false
      },
      address: {
        value: this.siteSummary.site.address,
        disabled: false
      },
      address2: { value: this.siteSummary.site.address2, disabled: false },
      addressTown: {
        value: this.siteSummary.site.addressTown,
        disabled: false
      },
      addressCounty: {
        value: this.siteSummary.site.addressCounty,
        disabled: false
      },
      addressPCode: {
        value: this.siteSummary.site.addressPCode,
        disabled: false
      },
      addressLat: { value: this.siteSummary.site.addressLat, disabled: false },
      addressLng: { value: this.siteSummary.site.addressLng, disabled: false }
    });
  }
  ngOnDestroy(): void {
    if (this.addressUpdate$) {
      this.addressUpdate$.unsubscribe();
    }
  }

  clickMap($event) {
    this.editAddress.get('addressLat').setValue($event.coords.lat);
    this.editAddress.get('addressLng').setValue($event.coords.lng);
  }

  onSubmit() {
    const updateData: SiteUpdateAddressRequestViewModel = {
      siteId: this.siteSummary.site.id,
      name: this.editAddress.get('name').value,
      address: this.editAddress.get('address').value,
      address2: this.editAddress.get('address2').value,
      addressTown: this.editAddress.get('addressTown').value,
      addressCounty: this.editAddress.get('addressCounty').value,
      addressPCode: this.editAddress.get('addressPCode').value,
      addressLat: this.editAddress.get('addressLat').value,
      addressLng: this.editAddress.get('addressLng').value
    };

    this.http.post(ApiEndpoint.site.updateAddress, updateData).subscribe(
      () => {
        this.toastr.success('Address updated');
        this.modal.close(true);
      },
      err => {
        this.toastr.error(err.message, 'Address update error');
      }
    );
  }

  queryAllFromGoogle() {
    return this.queryFromGoogle(true, true);
  }

  queryFromGoogle(address: boolean, coords: boolean) {
    const addressParts: string[] = [
      this.editAddress.get('name').value,
      this.editAddress.get('address').value,
      this.editAddress.get('address2').value,
      this.editAddress.get('addressTown').value,
      this.editAddress.get('addressCounty').value,
      this.editAddress.get('addressPCode').value
    ];

    const addressPartsFiltered = addressParts.filter(
      a => a && a.length > 0
    );

    const fullAddress = addressPartsFiltered.join(',');

    let queryParams = new HttpParams();
    queryParams = queryParams.append('address', fullAddress);
    queryParams = queryParams.append(
      'key',
      environment.googleMaps.geoCodeApiKey
    );

    this.http
      .get<any>(ApiEndpoint.googleMaps.geoCode, { params: queryParams })
      .subscribe(res => {
        if (res.results.length === 0) {
          return;
        }

        const firstResult = res.results[0];

        if (firstResult.geometry && coords) {
          this.editAddress
            .get('addressLat')
            .setValue(firstResult.geometry.location.lat);
          this.editAddress
            .get('addressLng')
            .setValue(firstResult.geometry.location.lng);
        }

        if (address) {
          const addressComponents = firstResult.address_components;

          if (addressComponents) {
            const subPremQuery = addressComponents.filter(
              a => a.types.indexOf('subpremise') !== -1
            );
            const premQuery = addressComponents.filter(
              a => a.types.indexOf('premise') !== -1
            );

            // If we have a premises name, and there is no subpremises, this is the name of the site
            // For example "Ashington Leisure Centre" would fall under this logic
            if (subPremQuery.length === 0 && premQuery.length > 0) {
              this.editAddress.get('name').setValue(premQuery[0].long_name);
            }

            if (subPremQuery.length > 0 && premQuery.length > 0) {
              this.editAddress
                .get('address')
                .setValue(
                  `${subPremQuery[0].long_name} ${premQuery[0].long_name}`
                );
            } else {
              // Try and work out numbered address
              const streetNumQuery = addressComponents.filter(
                a => a.types.indexOf('street_num') !== -1
              );
              const routeQuery = addressComponents.filter(
                a => a.types.indexOf('route') !== -1
              );
              if (streetNumQuery.length > 0 && routeQuery.length > 0) {
                // For example 5 Roadname Road
                this.editAddress
                  .get('address')
                  .setValue(
                    `${streetNumQuery[0].long_name} ${routeQuery[0].long_name}`
                  );
              } else if (routeQuery.length > 0) {
                this.editAddress
                  .get('address')
                  .setValue(routeQuery[0].long_name);
              }
            }

            const localityQuery = addressComponents.filter(
              a => a.types.indexOf('locality') !== -1
            );
            const neighbourhoodQuery = addressComponents.filter(
              a => a.types.indexOf('neighbourhood') !== -1
            );

            if (neighbourhoodQuery.length > 0) {
              // If we have neighbourhood, set address line 2 to this and if possible set address town to locality
              this.editAddress
                .get('address2')
                .setValue(neighbourhoodQuery[0].long_name);

              if (localityQuery.length > 0) {
                this.editAddress
                  .get('addressTown')
                  .setValue(localityQuery[0].long_name);
              }
            } else if (localityQuery.length > 0) {
              // If no neighbourhood but have locality, set address line 2 to locality
              this.editAddress
                .get('address2')
                .setValue(localityQuery[0].long_name);
            }

            const townQuery = addressComponents.filter(
              a => a.types.indexOf('postal_town') !== -1
            );
            if (townQuery.length > 0) {
              this.editAddress
                .get('addressTown')
                .setValue(townQuery[0].long_name);
            }

            // Try and get county, google calls these admin area levels from US addresses
            let countyQuery = addressComponents.filter(
              a => a.types.indexOf('administration_area_level_2') !== -1
            );

            if (countyQuery.length === 0) {
              countyQuery = addressComponents.filter(
                a => a.types.indexOf('administration_area_level_1') !== -1
              );
            }
            if (countyQuery.length > 0) {
              this.editAddress
                .get('addressCounty')
                .setValue(countyQuery[0].long_name);
            }

            const postcodeQuery = addressComponents.filter(
              a => a.types.indexOf('postal_code') !== -1
            );
            if (postcodeQuery.length > 0) {
              this.editAddress
                .get('addressPCode')
                .setValue(postcodeQuery[0].long_name);
            }
          }
        }
      });
  }

  queryLocationFromGoogle() {
    return this.queryFromGoogle(false, true);
  }
}
