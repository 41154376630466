import { ActivatedRoute, Router } from '@angular/router';
import { SignalRService } from './../../signal-r.service';
import { ApiEndpoint } from './../../enum/apiendpoints.enum';
import { SiteSearchRequestViewModel } from '../../viewmodels/generated/site-search-request-view-model';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, retryWhen, delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SiteSearchViewModel } from 'src/app/viewmodels/generated/site-search-view-model';

@Component({
  selector: 'app-sitesearch',
  templateUrl: './sitesearch.component.html',
  styleUrls: ['./sitesearch.component.scss']
})
export class SiteSearchComponent implements OnInit, OnDestroy {
  searchTextControl = new FormControl();
  searchText$: Subscription;
  searchResults: SiteSearchViewModel[];
  addressUpdate$: Subscription;
  route$: Subscription;
  lastSearch: string;

  constructor(
    private http: HttpClient,
    private signalr: SignalRService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // When we edit the search box (with debounce so no spam), update query parameters
    this.searchText$ = this.searchTextControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(val => {
        this.onTextSearchUpdate(val);
        this.router.navigate([], { queryParams: { q: val } });
      });

    // On site update event, replay search request
    this.addressUpdate$ = this.signalr.siteAddressUpdateObserver.subscribe(
      () => {
        this.onTextSearchUpdate(this.lastSearch);
      }
    );

    // When queryparams changes, look for q and query on it
    this.route$ = this.route.queryParams.subscribe(params => {
      if (params.q) {
        this.onTextSearchUpdate(params.q);
        this.searchTextControl.setValue(params.q);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.searchText$) {
      this.searchText$.unsubscribe();
    }
    if (this.addressUpdate$) {
      this.addressUpdate$.unsubscribe();
    }
    if (this.route$) {
      this.route$.unsubscribe();
    }
  }

  async onTextSearchUpdate(val) {
    this.lastSearch = val;
    const request: SiteSearchRequestViewModel = {
      searchTerm: val,
      numResults: 100
    };

    this.http
      .post<SiteSearchViewModel[]>(ApiEndpoint.site.search, request)
      .pipe(retryWhen(delay(100)))
      .subscribe(searchResults => {
        this.searchResults = searchResults;
      });
  }
}
