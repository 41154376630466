import { Component, OnInit, OnDestroy } from '@angular/core';
import { SiteJobsViewModel } from 'src/app/viewmodels/generated/site-jobs-view-model';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ApiEndpoint } from 'src/app/enum/apiendpoints.enum';
import { retry, retryWhen, delay } from 'rxjs/operators';
import { JobState } from 'src/app/viewmodels/generated/job-state';
import { Sort, MatSortable } from '@angular/material/sort';
import { JobStatus } from 'src/app/viewmodels/generated/job-status';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteSummaryViewModel } from 'src/app/viewmodels/generated/site-summary-view-model';
import { JobType } from 'src/app/viewmodels/generated/job-type';
import { SignalRService } from 'src/app/signal-r.service';
import { JobService } from 'src/app/service/job.service';
import { EditJobComponent } from '../edit-job/edit-job.component';

@Component({
  selector: 'app-site-jobs',
  templateUrl: './site-jobs.component.html',
  styleUrls: ['./site-jobs.component.scss']
})
export class SiteJobsComponent implements OnInit, OnDestroy {
  siteId: number;
  siteJobView: SiteJobsViewModel;
  route$: Subscription;

  jobStatuses = JobStatus;
  siteSummary: SiteSummaryViewModel;
  siteUpdate$: Subscription;
  jobUpdated$: Subscription;
  currentSort: Sort;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private modalService: NgbModal,
    private signalr: SignalRService,
    public jobService: JobService
  ) {}

  ngOnInit() {
    this.route$ = this.route.params.subscribe(routeParams => {
      this.siteId = +routeParams.id;
      this.getJobsForSite(this.siteId);
      this.getSite(this.siteId);
    });

    this.siteUpdate$ = this.signalr.siteUpdateObserver.subscribe(siteid => {
      if (this.siteId === siteid) {
        this.getSite(this.siteId);
      }
    });

    this.jobUpdated$ = this.signalr.jobUpdatedObserver.subscribe(res => {
      if (this.siteId === res.siteId) {
        this.getJobsForSite(this.siteId);
      }
    });

    // Default sort
    this.sortData({
      active: 'lasttupdated',
      direction: 'desc'
    });
  }

  ngOnDestroy() {
    if (this.siteUpdate$) {
      this.siteUpdate$.unsubscribe();
    }
    if (this.jobUpdated$) {
      this.jobUpdated$.unsubscribe();
    }
    if (this.route$) {
      this.route$.unsubscribe();
    }
  }

  getJobsForSite(siteId) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', siteId.toString());
    this.http
      .get<SiteJobsViewModel>(ApiEndpoint.site.getjobs, {
        params: queryParams
      })
      .pipe(retryWhen(delay(100)))
      .subscribe(res => {
        this.siteJobView = res;
        if (this.currentSort) {
          this.sortData(this.currentSort);
        }
      });
  }

  getSite(siteId) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', siteId.toString());
    this.http
      .get<SiteSummaryViewModel>(ApiEndpoint.site.summary, {
        params: queryParams
      })
      .pipe(retryWhen(delay(100)))
      .subscribe(res => {
        this.siteSummary = res;
        if (this.currentSort) {
          this.sortData(this.currentSort);
        }
      });
  }

  getJobDescriptionWithNotes(job: JobState) {
    return job.descriptionWithNotes.replace('\n', '<br />');
  }

  isJobVisitBooked(job: JobState) {
    return job.assignedTimeEnd !== null ? 'Yes' : 'No';
  }

  editJob(job: JobState) {
    const modalRef = this.modalService.open(EditJobComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.site = this.siteSummary.site;
    modalRef.componentInstance.job = job;
  }

  addJob() {
    const modalRef = this.modalService.open(EditJobComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.site = this.siteSummary.site;
    modalRef.componentInstance.job = null;
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.currentSort = sort;

    const isAsc = sort.direction === 'asc';

    if (!this.siteJobView || !this.siteJobView.jobs) {
      return;
    }

    this.siteJobView.jobs.sort((a, b) => {
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'desc':
          return this.compare(
            a.descriptionWithNotes,
            b.descriptionWithNotes,
            isAsc
          );

        case 'type':
          return this.compare(
            this.jobService.getJobType(a),
            this.jobService.getJobType(b),
            isAsc
          );
        case 'lasttupdated':
          return this.compare(a.lastUpdated, b.lastUpdated, isAsc);
        case 'isassigned':
          return this.compare(
            this.isJobVisitBooked(a),
            this.isJobVisitBooked(b),
            isAsc
          );
        case 'status':
          return this.compare(
            this.jobStatuses[a.status],
            this.jobStatuses[b.status],
            isAsc
          );
      }
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  createButtonTooltip() {
    if (
      this.siteSummary &&
      this.siteSummary.site &&
      !this.siteSummary.site.addressLat &&
      !this.siteSummary.site.addressLng
    ) {
      return 'This site must have a map position before you can create jobs';
    }
    return '';
  }
}
