<app-site-navbar current="images"></app-site-navbar>

<div class="container" *ngIf="!siteGallery">
  <div class="row">
    <div class="col d-flex justify-content-center">
      <mat-spinner clas="text-center"></mat-spinner>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="siteGallery">
  <div class="row">
    <div class="col text-center" *ngFor="let image of siteGallery.images">
      <img
        width="256"
        height="256"
        [src]="image.mediaLink"
        (click)="openImageGalleryAt(image)"
        [ngStyle]="{
          transform: 'rotate(' + getImageRotation(image) + 'deg)'
        }"
      />
    </div>
  </div>
</div>
