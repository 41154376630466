<div
  *ngIf="showEditedElsewhereWarning"
  class="alert alert-warning text-center"
  role="alert"
>
  System has been modified after this form was opened. You must re-open the
  editor.
</div>

<div class="container" *ngIf="siteSummary">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div
        class="col font-weight-bold text-center site-summary-header"
        *ngIf="system.id !== -1"
      >
        Editing System - {{ system.name }}
      </div>
      <div
        class="col font-weight-bold text-center site-summary-header"
        *ngIf="system.id === -1"
      >
        Add System
      </div>
    </div>
    <div class="row"><div class="col">&nbsp;</div></div>
    <div class="row">
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          Name
        </div>
        <input type="text" formControlName="name" class="form-control" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          Notes
        </div>
        <textarea formControlName="notes" class="form-control"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          System Type
        </div>
        <select class="form-control" formControlName="isMotorised">
          <option value="2">Motorised</option>
          <option value="1">Manual</option>
          <option value="0">Unknown</option>
        </select>
      </div>
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          Forge System
        </div>
        <select class="form-control" formControlName="isOurs">
          <option value="2">Yes</option>
          <option value="1">No</option>
          <option value="0">Unknown</option>
        </select>
      </div>
    </div>
    <div class="row"><div class="col">&nbsp;</div></div>
    <div class="row">
      <div class="col text-right">
        <button
          type="submit"
          class="btn btn-success"
          [disabled]="saving || deleting || this.form.disabled"
        >
          {{ saving ? "Saving..." : "Save" }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          [disabled]="saving || deleting || this.form.disabled"
          (click)="onClickDelete()"
        >
          {{ deleting ? "Deleting..." : "Delete" }}
        </button>
      </div>
    </div>
  </form>
</div>
