<div class="container-fluid" *ngIf="!selectedJob">
  <div class="row">
    <div class="col-3">&nbsp;</div>
    <div class="col-6">
      <input
        type="text"
        placeholder="Search"
        [formControl]="searchTextControl"
        class="form-control"
      />
    </div>
    <div class="col-3"></div>
  </div>

  <div class="row">
    <div class="col-3">&nbsp;</div>
    <div class="col-6 text-center">
      Results
    </div>
    <div class="col-3"></div>
  </div>

  <div class="container-fluid" *ngFor="let result of searchResults">
    <div class="row">
      <div class="col site-summary-header">
        <i
          class="fas fa-circle"
          [style.color]="jobService.getMapMarkerIconFillColor(result.job)"
        ></i>
        <span
          [innerHtml]="highlightRegexSearch(result.job.id) | noEscapeHtml"
        ></span>
        -
        <span
          [innerHtml]="
            highlightRegexSearch(result.site.fullAddress) | noEscapeHtml
          "
        ></span>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <span
          [innerHtml]="
            highlightRegexSearch(
              jobService.getFormattedDescription(result.job)
            ) | noEscapeHtml
          "
        ></span>
      </div>
      <div class="col-3 text-right">
        <button class="btn btn-primary" (click)="selectJob(result.job)">
          Select
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="selectedJob">
  <div class="row">
    <div class="col font-weight-bold text-center site-summary-header">
      Select Engineers
    </div>
  </div>

  <table class="table table-striped table-bordered">
    <th class="text-center">Name</th>
    <th class="text-center">Assigned</th>
    <th class="text-center">Is Primary</th>

    <tr *ngFor="let result of engineers">
      <td class="text-center">{{ result.name }}</td>
      <td class="text-center">
        <input
          type="checkbox"
          [checked]="isSelectedEngineer(result)"
          (change)="onEngineerChanged(result, $event)"
        />
      </td>
      <td class="text-center">
        <input
          type="checkbox"
          [checked]="isPrimaryEngineer(result)"
          (change)="onPrimaryEngineerChanged(result, $event)"
        />
      </td>
    </tr>
  </table>

  <div class="row">
    <div class="col-6">
      <button class="btn btn-primary" (click)="saveResults()">
        Save
      </button>
    </div>
    <div class="col-6 text-right">
      <button
        *ngIf="editingExisting"
        class="btn btn-danger"
        (click)="deleteJobAssignment()"
      >
        Unassign
      </button>
    </div>
  </div>
</div>
