import { AuthInterceptor } from "./interceptor/http.interceptor";
import { AngularFireModule } from "@angular/fire";
import { AuthService } from "./service/auth.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import {
  NgbModule,
  NgbActiveModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { LoginComponent } from "./comp/login/login.component";
import { HomepageComponent } from "./comp/homepage/homepage.component";
import { NavigationbarComponent } from "./comp/navigationbar/navigationbar.component";
import { AngularFireAuth, AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "src/environments/environment";
import { MatMenuModule } from "@angular/material/menu";
import { MatBadgeModule } from "@angular/material/badge";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { MatToolbarModule } from "@angular/material/toolbar";
import { CurrentuserComponent } from "./comp/currentuser/currentuser.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SiteSearchComponent } from "./comp/sitesearch/sitesearch.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { NoEscapeHtmlPipe } from "./pipe/no-escape-html.pipe";
import { SiteSummaryComponent } from "./comp/site-summary/site-summary.component";
import { AgmCoreModule } from "@agm/core";
import { EditSiteAddressComponent } from "./comp/edit-site-address/edit-site-address.component";
import { ToastrModule } from "ngx-toastr";
import { SiteNavbarComponent } from "./comp/site-navbar/site-navbar.component";
import { SiteJobsComponent } from "./comp/site-jobs/site-jobs.component";
import { EditSystemComponent } from "./comp/edit-system/edit-system.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ImageGalleryComponent } from "./comp/image-gallery/image-gallery.component";
import { SiteGalleryComponent } from "./comp/site-gallery/site-gallery.component";
import { MapComponent } from "./comp/map/map.component";
import { AdminComponent } from "./comp/admin/admin.component";
import { EditJobComponent } from "./comp/edit-job/edit-job.component";
import { EditContactComponent } from "./comp/edit-contact/edit-contact.component";
import { WarningAreyousureComponent } from "./comp/warning-areyousure/warning-areyousure.component";
import { SchedulerComponent } from "./comp/scheduler/scheduler.component";
import { SelectJobComponent } from "./comp/select-job/select-job.component";
import { PrintQueueComponent } from "./comp/printqueue/printqueue.component";
import { NgxFileDropModule } from "ngx-file-drop";
import { EditSiteMaintenanceComponent } from './comp/edit-site-maintenance/edit-site-maintenance.component';
import { CurrencyOrUnknownPipe } from './pipe/currency-or-unknown.pipe';
import { CurrencyPipe } from "@angular/common";
import { ConfigComponent } from './comp/config/config.component';
import { EditConfigComponent } from './comp/edit-config/edit-config.component';

@NgModule({
  entryComponents: [
    EditSiteAddressComponent,
    EditSiteMaintenanceComponent,
    EditConfigComponent,
    EditSystemComponent,
    EditJobComponent,
    EditContactComponent,
    WarningAreyousureComponent,
    ImageGalleryComponent,
    SelectJobComponent,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HomepageComponent,
    NavigationbarComponent,
    CurrentuserComponent,
    SiteSearchComponent,
    NoEscapeHtmlPipe,
    SiteSummaryComponent,
    EditSiteAddressComponent,
    SiteNavbarComponent,
    SiteJobsComponent,
    EditSystemComponent,
    EditJobComponent,
    ImageGalleryComponent,
    SiteGalleryComponent,
    MapComponent,
    AdminComponent,
    EditContactComponent,
    WarningAreyousureComponent,
    SchedulerComponent,
    SelectJobComponent,
    PrintQueueComponent,
    EditSiteMaintenanceComponent,
    CurrencyOrUnknownPipe,
    ConfigComponent,
    EditConfigComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    MatBadgeModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressBarModule,
    FullCalendarModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatSortModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps.mapsApiKey,
      libraries: ["places"],
    }),
    ReactiveFormsModule,
    NgxFileDropModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    AngularFireAuth,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
