export const environment = {
  production: true,
  baseUrl: "https://staging.forgeleisure.host/",
  apiBase: "https://api.staging.forgeleisure.host/api/",
  storage: "https://storage-staging.forgeleisure.host/",
  firebase: {
    apiKey: "AIzaSyCouaAfAJa4lAYf5QqMAgWd_fk60j36H6k",
    authDomain: "forge-c9b7b.firebaseapp.com",
    databaseURL: "https://forge-c9b7b.firebaseio.com",
    projectId: "forge-c9b7b",
    storageBucket: "forge-c9b7b.appspot.com",
    messagingSenderId: "406115909553",
  },
  googleMaps: {
    geoCodeApiKey: "AIzaSyBl65L6AT5bbXa5AVQaTIlVk_M_I-lVyyo",
    mapsApiKey: "AIzaSyDFctbXqQe9F9nLgAPXmGC19R4hlRnTD5w",
  },
};
