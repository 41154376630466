<div
  *ngIf="showEditedElsewhereWarning"
  class="alert alert-warning text-center"
  role="alert"
>
  This site has been modified and the information is out of date.<br />You must
  re-open this form to carry out any changes.
</div>
<form [formGroup]="editAddress" (ngSubmit)="onSubmit()">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col font-weight-bold text-center site-summary-header">
            Address
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              formControlName="name"
              class="form-control"
              placeholder="Name"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              formControlName="address"
              class="form-control"
              placeholder="Address"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              formControlName="address2"
              class="form-control"
              placeholder="Address (line 2)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              formControlName="addressTown"
              class="form-control"
              placeholder="Town"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              formControlName="addressCounty"
              class="form-control"
              placeholder="County"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              formControlName="addressPCode"
              class="form-control"
              placeholder="Postcode"
            />
          </div>
        </div>

        <div class="row">
          <div class="col text-center">
            <button
              type="button"
              class="btn btn-secondary"
              [disabled]="editAddress.disabled"
              (click)="queryAllFromGoogle()"
            >
              Update with Google Maps
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <input
              type="submit"
              class="btn btn-success"
              [disabled]="!editAddress.valid"
              value="Save"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col font-weight-bold text-center site-summary-header">
            Position
          </div>
        </div>
        <div class="row">
          <div class="col font-weight-bold text-center">
            <agm-map
              [latitude]="siteSummary.site.addressLat"
              [longitude]="siteSummary.site.addressLng"
              (mapClick)="clickMap($event)"
            >
              <agm-marker
                [latitude]="editAddress.get('addressLat').value"
                [longitude]="editAddress.get('addressLng').value"
              >
              </agm-marker>
            </agm-map>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <input
              type="text"
              formControlName="addressLat"
              class="form-control"
              placeholder="Latitude"
            />
          </div>
          <div class="col-6">
            <input
              type="text"
              formControlName="addressLng"
              class="form-control"
              placeholder="Longitude"
            />
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <button
              type="button"
              class="btn btn-secondary"
              [disabled]="editAddress.disabled"
              (click)="queryLocationFromGoogle()"
            >
              Update with Google Maps
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
