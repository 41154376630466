import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "src/app/service/auth.service";
import { HttpClient } from "@angular/common/http";
import { ApiEndpoint } from "src/app/enum/apiendpoints.enum";
import { ToastrService } from "ngx-toastr";
import { SignalRService } from "src/app/signal-r.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
  isAdmin: Observable<boolean>;

  isElasticSearchSyncPending = false;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private toastr: ToastrService
  ) {
    this.isAdmin = this.authService.hasClaim("isAdmin");
  }

  ngOnInit() {}

  elasticSearchSync() {
    this.isElasticSearchSyncPending = true;

    this.http.get(ApiEndpoint.admin.elasticSync).subscribe(
      () => {
        this.isElasticSearchSyncPending = false;
        this.toastr.success("Successfully synced ElasticSearch");
      },
      (err) => {
        this.isElasticSearchSyncPending = false;
        console.log(err);
        this.toastr.error(err.message, "Error syncing ElasticSearch");
      }
    );
  }
}
