import { HomepageComponent } from "./comp/homepage/homepage.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./comp/login/login.component";
import { SiteSearchComponent } from "./comp/sitesearch/sitesearch.component";
import { SiteSummaryComponent } from "./comp/site-summary/site-summary.component";
import { SiteJobsComponent } from "./comp/site-jobs/site-jobs.component";
import { SiteGalleryComponent } from "./comp/site-gallery/site-gallery.component";
import { MapComponent } from "./comp/map/map.component";
import { AdminComponent } from "./comp/admin/admin.component";
import { SchedulerComponent } from "./comp/scheduler/scheduler.component";
import { Scheduler } from "rxjs";
import { PrintQueueComponent } from "./comp/printqueue/printqueue.component";
import { ConfigComponent } from "./comp/config/config.component";

const routes: Routes = [
  { path: "", component: HomepageComponent },
  { path: "login", component: LoginComponent },
  { path: "site", component: SiteSearchComponent },
  { path: "site/:id/summary", component: SiteSummaryComponent },
  { path: "site/:id/jobs", component: SiteJobsComponent },
  { path: "site/:id/images", component: SiteGalleryComponent },
  { path: "map", component: MapComponent },
  { path: "admin", component: AdminComponent },
  { path: "config", component: ConfigComponent },
  { path: "scheduler", component: SchedulerComponent },
  { path: "printQueue", component: PrintQueueComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
