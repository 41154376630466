import { sort } from "fast-sort";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { PrintQueueService } from "./../../service/printqueue.service";
import { SelectJobComponent } from "./../select-job/select-job.component";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { SignalRService } from "./../../signal-r.service";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { JobSearchRequest } from "src/app/viewmodels/generated/job-search-request";
import { JobType } from "src/app/viewmodels/generated/job-type";
import { JobStatus } from "src/app/viewmodels/generated/job-status";
import { JobSearchResponse } from "src/app/viewmodels/generated/job-search-response";
import { ApiEndpoint } from "src/app/enum/apiendpoints.enum";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as moment from "moment";
import { JobService } from "src/app/service/job.service";
import { User } from "src/app/viewmodels/generated/user";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { EventApi, Duration, createDuration } from "@fullcalendar/core";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { EditJobComponent } from "../edit-job/edit-job.component";
import { WarningAreyousureComponent } from "../warning-areyousure/warning-areyousure.component";
import { CalendarOptions } from "@fullcalendar/core";
import $ from "jquery";
import * as R from "rambda";

@Component({
  selector: "app-scheduler",
  templateUrl: "./scheduler.component.html",
  styleUrls: ["./scheduler.component.scss"],
})
export class SchedulerComponent implements OnInit, OnDestroy {
  @ViewChild("calendar", { static: true }) calendar: FullCalendarComponent;

  jobUpdated$: Subscription;

  fetchCalendarJobs$: Subscription;

  snapDuration: Duration = createDuration({
    milliseconds: 15 * 60 * 1000,
  });

  constructor(
    private http: HttpClient,
    private jobService: JobService,
    private signalRService: SignalRService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private printQueueService: PrintQueueService
  ) { }

  visibleJobs: JobSearchResponse[];
  hoveredJob: JobSearchResponse;
  viewDateStart: Date;
  viewDateEnd: Date;
  calendarExcludedDays: number[] = [0, 6];
  calendarEvents = [];
  engineers: User[];
  unassignedJobs: JobSearchResponse[];
  jobSelectorModal: NgbModalRef;

  calendarEventsCallback: any;
  calendarHeader = "";
  calendarOptions: CalendarOptions;

  visibleJobTypes: JobType[] = [
    JobType.Unknown,
    JobType.Delivery,
    JobType.Installation,
    JobType.ReplacementCover,
    JobType.Repair,
    JobType.Service,
    JobType.Survey,
  ];

  ngOnInit() {
    this.viewDateStart = moment().startOf("week").toDate();
    this.viewDateEnd = moment(this.viewDateStart).add(7, "days").toDate();

    this.fetchJobs();

    this.jobUpdated$ = this.signalRService.jobUpdatedObserver.subscribe((res) =>
      this.fetchJobs()
    );

    this.calendarOptions = {
      plugins: [timeGridPlugin, interactionPlugin],
      weekNumbers: true,
      editable: true,
      selectable: true,
      snapDuration: createDuration({ minute: 15 }),
      slotDuration: createDuration({ minute: 15 }),
      timeZone: "local",
      weekends: false,
      nowIndicator: true,
      headerToolbar: false,
      height: "calc(100vh - 102px)",
      eventDrop: this.eventDrop.bind(this),
      eventResize: this.eventResize.bind(this),
      select: this.onSelect.bind(this),
      eventClick: this.eventClick.bind(this),
      eventMouseEnter: this.eventRender.bind(this),
    };
  }

  ngOnDestroy() {
    if (this.jobUpdated$) {
      this.jobUpdated$.unsubscribe();
    }
  }

  async fetchEngineers() {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("claim", "engineer");

    const res = await this.http
      .get<User[]>(ApiEndpoint.user.getByClaim, { params: queryParams })
      .pipe(take(1))
      .toPromise();
    this.engineers = res;
  }

  fetchJobs() {
    this.fetchCalendarJobs();
    this.fetchUnassignedJobs();
  }

  async fetchCalendarJobs() {
    // We need engineer data
    if (!this.engineers) {
      await this.fetchEngineers();
    }

    const request: JobSearchRequest = {
      query: "",
      types: this.visibleJobTypes,
      statuses: [JobStatus.Pending, JobStatus.Complete, JobStatus.Legacy],
      from: moment(this.viewDateStart).subtract(7, "days").toDate(),
      to: moment(this.viewDateEnd || this.viewDateStart)
        .add(7, "days")
        .toDate(),
      onlyUnassigned: false,
    };

    // If there's a pending fetch, cancel it
    if (this.fetchCalendarJobs$) {
      this.fetchCalendarJobs$.unsubscribe();
    }

    this.fetchCalendarJobs$ = this.http
      .post<JobSearchResponse[]>(ApiEndpoint.job.query, request)
      .subscribe((res) => {
        this.visibleJobs = res;
        this.createCalendarEvents();
      });
  }

  fetchUnassignedJobs() {
    const request: JobSearchRequest = {
      query: "",
      types: this.visibleJobTypes,
      statuses: [JobStatus.Pending],
      from: null,
      to: null,
      onlyUnassigned: true,
    };

    this.http
      .post<JobSearchResponse[]>(ApiEndpoint.job.query, request)
      .subscribe((res) => {
        this.unassignedJobs = res;
      });
  }

  createCalendarEvents() {
    this.updateCalendarHeader();
    const events = [];

    this.visibleJobs.forEach((jobResult) => {
      const engineer = this.engineers.find(
        (a) => a.id === jobResult.job.assignedUsersList[0]
      );

      let colorBackground = "#ffffff";
      let colorText = "#000000";

      if (engineer) {
        colorBackground = engineer.configuration.calendarBackground;

        colorText = engineer.configuration.calendarText;
      }

      let title = `${jobResult.site.id} - ${jobResult.site.name}`;

      if (jobResult.site.addressPCode) {
        title += `, ${jobResult.site.addressPCode}`;
      }

      title += `\n${jobResult.job.descriptionWithNotes
        }\nAssigned to: ${jobResult.job.assignedUsersList
          .map((user) => this.engineers.find((a) => a.id === user).name)
          .join(", ")}`;

      const ev = {
        start: moment(jobResult.job.assignedTimeStart).toDate(),
        end: moment(jobResult.job.assignedTimeEnd).toDate(),
        id: jobResult.job.id,
        title,
        backgroundColor: colorBackground,
        borderColor: colorBackground,
        textColor: colorText,
        editable: true,
      };
      events.push(ev);
    });

    this.calendarEvents = events;
    this.calendarOptions.events = events;
  }

  eventDrop(event) {
    const newEvent = event.event as EventApi;
    const jobResult = this.visibleJobs.find(
      (a) => a.job.id.toString() === newEvent.id
    );

    jobResult.job.assignedTimeStart = newEvent.start;
    jobResult.job.assignedTimeEnd = newEvent.end;

    const calendarEvent = this.calendarEvents.find(
      (a) => a.id.toString() === newEvent.id
    );

    // Update the event and prevent further edits until the update notification comes through (when ES updates)
    calendarEvent.start = newEvent.start;
    calendarEvent.end = newEvent.end;
    calendarEvent.editable = false;

    this.jobService.update(jobResult.job).subscribe(
      (res) => {
        this.toastr.success("Job Updated");
      },
      (err) => {
        this.toastr.error(
          "Error updating job, see console for more information"
        );
        console.log(err);
      }
    );
  }

  eventResize(event) {
    const newEvent = event.event as EventApi;
    const jobResult = this.visibleJobs.find(
      (a) => a.job.id.toString() === newEvent.id
    );

    jobResult.job.assignedTimeStart = newEvent.start;
    jobResult.job.assignedTimeEnd = newEvent.end;

    const calendarEvent = this.calendarEvents.find(
      (a) => a.id.toString() === newEvent.id
    );

    // Update the event and prevent further edits until the update notification comes through (when ES updates)
    calendarEvent.start = newEvent.start;
    calendarEvent.end = newEvent.end;
    calendarEvent.editable = false;

    this.jobService.update(jobResult.job).subscribe(
      (res) => {
        this.toastr.success("Job Updated");
      },
      (err) => {
        this.toastr.error(
          "Error updating job, see console for more information"
        );
        console.log(err);
      }
    );
  }

  onSelect(event) {
    console.log(event);
    const { start, end }: { start: Date; end: Date } = event;

    this.jobSelectorModal = this.modalService.open(SelectJobComponent, {
      windowClass: "m-modal-window",
    });
    this.jobSelectorModal.componentInstance.unassignedJobs = this.unassignedJobs;
    this.jobSelectorModal.componentInstance.engineers = this.engineers;
    this.jobSelectorModal.componentInstance.start = start;
    this.jobSelectorModal.componentInstance.end = end;
  }

  eventClick(event) {
    const res = this.visibleJobs.find((a) => a.job.id === +event.event.id);

    if (!res) {
      return;
    }

    this.jobSelectorModal = this.modalService.open(SelectJobComponent, {
      windowClass: "m-modal-window",
    });
    this.jobSelectorModal.componentInstance.unassignedJobs = this.unassignedJobs;
    this.jobSelectorModal.componentInstance.engineers = this.engineers;
    this.jobSelectorModal.componentInstance.start = res.job.assignedTimeStart;
    this.jobSelectorModal.componentInstance.end = res.job.assignedTimeEnd;
    this.jobSelectorModal.componentInstance.selectedJob = res.job;
    this.jobSelectorModal.componentInstance.editingExisting = true;
  }

  eventRender(event) {
    const jobResult = this.visibleJobs.find(
      (a) => a.job.id === +event.event.id
    );

    let title = `${jobResult.site.id} - ${jobResult.site.name}`;

    if (jobResult.site.addressPCode) {
      title += `, ${jobResult.site.addressPCode}`;
    }

    title += `\n${jobResult.job.descriptionWithNotes
      }\nAssigned to: ${jobResult.job.assignedUsersList
        .map((user) => this.engineers.find((a) => a.id === user).name)
        .join(", ")}`;

    $(event.el).attr("title", title);
  }

  getColumnHeader = (date) => {
    return moment(date).format("ddd DD/MM");
  };

  updateCalendarHeader() {
    if (!this.viewDateStart || !this.viewDateEnd) {
      this.calendarHeader = "";
    }

    this.calendarHeader = `${moment(this.viewDateStart).format(
      "ddd Do MMMM"
    )} - ${moment(this.viewDateEnd).format("ddd Do MMMM YYYY")}`;
  }

  calendarPrevious() {
    this.viewDateStart = moment(this.viewDateStart)
      .subtract(7, "days")
      .toDate();
    this.viewDateEnd = moment(this.viewDateEnd).subtract(7, "days").toDate();
    this.calendar.getApi().gotoDate(this.viewDateStart);
    this.fetchCalendarJobs();
  }

  calendarNext() {
    this.viewDateStart = moment(this.viewDateStart).add(7, "days").toDate();
    this.viewDateEnd = moment(this.viewDateEnd).add(7, "days").toDate();
    this.calendar.getApi().gotoDate(this.viewDateStart);
    this.fetchCalendarJobs();
  }

  calendarNow() {
    this.viewDateStart = moment().startOf("week").toDate();
    this.viewDateEnd = moment(this.viewDateStart).add(7, "days").toDate();
    this.calendar.getApi().gotoDate(this.viewDateStart);
    this.fetchCalendarJobs();
  }

  printAll() {
    if (!this.printQueueService.queue || !this.visibleJobs) {
      return;
    }

    const groupFunc = R.groupBy((a: JobSearchResponse) =>
      a.job.assignedUsersList[0].toString()
    );
    const groupedJobs = groupFunc(this.visibleJobs.filter(a => a?.job?.assignedUsersList && a.job.assignedUsersList.length > 0));

    Object.values(groupedJobs).forEach((jobs) => {
      const sortedByDate = jobs.sort(
        (a, b) =>
          moment(a.job.assignedTimeStart).unix() -
          moment(b.job.assignedTimeStart).unix()
      );

      sortedByDate.forEach((visibleEntry) => {
        // If the job is not on our current view (we preload the previous and last week), don't add to the queue
        if (this.viewDateStart >= new Date(visibleEntry.job.assignedTimeEnd)) {
          return;
        }
        if (this.viewDateEnd <= new Date(visibleEntry.job.assignedTimeStart)) {
          return;
        }

        // If the job is already in the queue, don't add it again
        if (
          this.printQueueService.queue.items.find(
            (i) =>
              i.type === "jobsheet" &&
              i.arguments &&
              i.arguments["jobId"] &&
              i.arguments["jobId"] === visibleEntry.job.id.toString()
          )
        ) {
          return;
        }

        this.printQueueService.queue.items.push({
          type: "jobsheet",
          arguments: { jobId: visibleEntry.job.id.toString() },
          url: null,
          error: null,
        });
      });
    });

    this.printQueueService.put();
  }
}
