<div class="container" *ngIf="!(isAdmin | async)">
  <div class="row">
    <div class="col alert alert-danger text-center">
      <div class="font-weight-bold">Error!</div>
      <div>
        Credential failure: Only administrators are allowed to access this page.
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="isAdmin | async">
  <div class="row text-center">
    <div class="col">
      <button
        class="btn btn-primary"
        (click)="elasticSearchSync()"
        [disabled]="isElasticSearchSyncPending"
      >
        {{
          isElasticSearchSyncPending ? "Syncing..." : "Synchonise ElasticSearch"
        }}
      </button>
      <div>
        Useful if the ElasticSearch index is out of sync with the database. For
        example, if you are getting errors moving jobs around the scheduler.
      </div>
    </div>
  </div>
</div>
