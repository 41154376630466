import { SignalRService } from "src/app/signal-r.service";
import { ApiEndpoint } from "src/app/enum/apiendpoints.enum";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PrintQueue } from "../viewmodels/generated/print-queue";
import { retryWhen, delay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PrintQueueService {
  public queue: PrintQueue;

  constructor(private http: HttpClient, private signalr: SignalRService) {
    this.getQueue();

    signalr.printQueueUpdate.subscribe((item) => {
      if (item.id === this.queue.id) {
        this.getQueue();
      }
    });
  }

  getQueue() {
    this.http
      .get<PrintQueue>(ApiEndpoint.printQueue.get)
      .pipe(retryWhen(delay(100)))
      .subscribe(
        (res) => {
          this.queue = res;

          if (!this.queue.items) {
            this.queue.items = [];
          }
        },
        (err) => {
          console.log("Error loading print queue");
          console.log(err);
        }
      );
  }

  getQueueLength() {
    if (!this.queue || !this.queue.items) {
      return 0;
    }

    return this.queue.items.length;
  }

  put() {
    if (!this.queue) {
      return;
    }

    this.http
      .put<PrintQueue>(ApiEndpoint.printQueue.put(this.queue.id), this.queue)
      .subscribe((res) => {
        this.queue = res;
        if (!this.queue.items) {
          this.queue.items = [];
        }
      });
  }
}
