<div class="container-fluid">
  <div class="row">
    <div class="col-3 text-left">
      <button
        type="button"
        class="btn btn-primary"
        (click)="calendarPrevious()"
      >
        <i class="fas fa-caret-left"></i>
      </button>
      <button type="button" class="btn btn-primary" (click)="calendarNext()">
        <i class="fas fa-caret-right"></i>
      </button>
      &nbsp;&nbsp;&nbsp;
      <button type="button" class="btn btn-primary" (click)="calendarNow()">
        Today
      </button>
    </div>
    <div class="col-6 text-center font-weight-bolder">
      {{ calendarHeader }}
    </div>
    <div class="col-3 text-right">
      <button type="button" class="btn btn-primary" (click)="printAll()">
        Print All <i class="fas fa-caret-down" *ngIf="!true"></i
        ><i class="fas fa-caret-up" *ngIf="false"></i>
      </button>
    </div>
  </div>
</div>

<div class="full-calendar-container">
  <full-calendar
    defaultView="timeGridWeek"
    deepChangeDetection="true"
    [options]="calendarOptions"
    (eventRender)="eventRender($event)"
    header=""
    #calendar
  ></full-calendar>
</div>
