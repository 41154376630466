import { auth } from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ApiEndpoint } from "src/app/enum/apiendpoints.enum";

@Injectable()
export class AuthService {
  currentUser: firebase.User = null;

  constructor(
    private firebaseAuth: AngularFireAuth,
    private router: Router,
    private http: HttpClient
  ) {
    firebaseAuth.authState.subscribe((currentAuth) => {
      this.currentUser = currentAuth;

      if (this.currentUser == null && router.url !== "/login") {
        router.navigateByUrl("/login");
      }
    });
  }

  idTokenObservable() {
    return this.firebaseAuth.idToken;
  }

  idTokenResultObservable() {
    return this.firebaseAuth.idTokenResult;
  }

  authStateObservable() {
    return this.firebaseAuth.authState;
  }

  async Login() {
    const credentials = await this.firebaseAuth.signInWithPopup(
      new auth.GoogleAuthProvider()
    );

    if (credentials != null) {
      const idToken = await this.getIDToken();

      // If our user isn't initialized, request the service initialize,
      // this is because the user has just been created on the authentication
      // side with the oauth provider details
      if (!idToken.claims.initialized) {
        await this.http.post(ApiEndpoint.user.initialize, null).toPromise();
      }
    }
  }

  Logout() {
    return this.firebaseAuth.signOut();
  }

  isLoggedIn() {
    return this.currentUser !== null;
  }

  getCurrentUser() {
    return this.currentUser;
  }

  async getUser() {
    const promise = new Promise((resolve) => {
      if (this.currentUser !== null) {
        resolve(true);
      } else {
        const auth$ = this.firebaseAuth.authState.subscribe((user) => {
          auth$.unsubscribe();
          if (user !== null) {
            this.currentUser = user;
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }
    });

    await promise;
    return this.currentUser;
  }

  async getJWT() {
    await this.getUser();
    return await this.currentUser.getIdToken(false);
  }

  async getIDToken() {
    if (!this.currentUser) {
      return null;
    }
    return await this.currentUser.getIdTokenResult(false);
  }

  getAuthTokenObservable(): Observable<string> {
    return new Observable((observer) => {
      this.getJWT().then((res) => {
        observer.next(res);
      });
    });
  }

  // Observable to check if the JWT of the current user has the associated claim
  hasClaim(claim: string): Observable<boolean> {
    return this.idTokenResultObservable().pipe(
      map((token) => token && token.claims[claim])
    );
  }
}
