<div class="container-fluid">
  <div class="row">
    <div class="col-10 text-center image-container">
      <div class="row">
        <div class="col-1 d-flex align-items-center justify-content-center">
          &nbsp;&nbsp;&nbsp;<i
            class="fas fa-arrow-left pointer"
            (click)="previousImage()"
          >
          </i>
        </div>
        <div class="col-10 d-flex align-items-center justify-content-center">
          <canvas #imageCanvas class="img viewing-image"></canvas>
        </div>
        <div class="col-1 d-flex align-items-center justify-content-center">
          <i class="fas fa-arrow-right pointer" (click)="nextImage()"></i>
        </div>
      </div>
      <div class="row">
        <div class="col" *ngFor="let image of buildBottomSlideshow()">
          <img
            [src]="image.mediaLink"
            class="img slideshowimage pointer"
            [ngClass]="{ currentimage: currentImage === image }"
            (click)="setImage(image)"
            [ngStyle]="{
              transform: 'rotate(' + getImageRotation(image) + 'deg)'
            }"
            width="128"
            height="128"
          />
        </div>
      </div>
    </div>
    <div class="col-2 text-center">
      <div class="row">
        <div class="col site-summary-header">
          {{ getFileName(currentImage.fileName) }}
        </div>
      </div>
      <div class="row">
        <div class="col site-summary-header">
          Rotate
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="btn btn-primary"
            type="button"
            (click)="rotateImageRight()"
          >
            <i class="fas fa-redo"></i>
          </button>
          <button
            class="btn btn-primary"
            type="button"
            (click)="rotateImageLeft()"
          >
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col site-summary-header">Dimensions</div>
      </div>
      <div class="row">
        <div class="col">
          <div>
            {{ getCanvasWidth() }} x
            {{ getCanvasHeight() }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col site-summary-header">Uploader</div>
      </div>
      <div class="row">
        <div class="col">
          <div>{{ currentImage.uploader }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col site-summary-header">Uploaded</div>
      </div>
      <div class="row">
        <div class="col">
          <div>{{ currentImage.created | date: "dd/MM/yy HH:mm:ss" }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
