import { PrintQueueService } from "./../../service/printqueue.service";
import { AuthService } from "./../../service/auth.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-currentuser",
  templateUrl: "./currentuser.component.html",
  styleUrls: ["./currentuser.component.scss"],
})
export class CurrentuserComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public printQueueService: PrintQueueService
  ) {}

  ngOnInit() {
    this.authService.isLoggedIn();
  }

  async clickLogin() {
    await this.authService.Login();
  }

  async clickLogout() {
    await this.authService.Logout();
  }

  getUserProviderData() {
    const user = this.authService.getCurrentUser();

    if (!user || user.providerData.length === 0) {
      return null;
    }
    const providerDataFirst = user.providerData[0];
    return providerDataFirst;
  }

  getDisplayName() {
    const profileData = this.getUserProviderData();
    if (profileData === null) {
      return "Unknown";
    }
    return profileData.displayName;
  }

  getPhoto() {
    const profileData = this.getUserProviderData();
    if (profileData === null) {
      return null;
    }
    return profileData.photoURL;
  }
}
