import { Component, OnInit, Input } from '@angular/core';
import { SiteSummaryViewModel } from 'src/app/viewmodels/generated/site-summary-view-model';
import { SiteSystem } from 'src/app/viewmodels/generated/site-system';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiEndpoint } from 'src/app/enum/apiendpoints.enum';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateSystemRequest } from 'src/app/viewmodels/generated/update-system-request';
import { DeleteSystemRequest } from 'src/app/viewmodels/generated/delete-system-request';
import { SignalRService } from 'src/app/signal-r.service';
import { Subscription } from 'rxjs';
import { SiteSystemIsMotorised } from 'src/app/viewmodels/generated/site-system-is-motorised';
import { SiteSystemIsOurs } from 'src/app/viewmodels/generated/site-system-is-ours';

@Component({
  selector: 'app-edit-system',
  templateUrl: './edit-system.component.html',
  styleUrls: ['./edit-system.component.scss']
})
export class EditSystemComponent implements OnInit {
  @Input() siteSummary: SiteSummaryViewModel;
  @Input() system: SiteSystem;

  form: FormGroup;
  saving: boolean;
  deleting: boolean;

  systemUpdate$: Subscription;
  showEditedElsewhereWarning: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private modal: NgbActiveModal,
    public signalr: SignalRService
  ) {}

  ngOnInit() {
    this.systemUpdate$ = this.signalr.siteSystemUpdateObserver.subscribe(
      res => {
        if (!this.system || this.system.id === -1) {
          return; // Adding new system
        }
        if (
          this.siteSummary.site.id === res.siteId &&
          this.system.id === res.systemId
        ) {
          this.showEditedElsewhereWarning = true;
          this.form.disable();
        }
      }
    );

    if (!this.system) {
      this.system = {
        id: -1,
        name: '',
        notes: '',
        isMotorised: SiteSystemIsMotorised.Unknown,
        isOurs: SiteSystemIsOurs.Unknown,
        isLowVoltage: false,
        isHighLevel: false
      };
    }

    this.form = this.formBuilder.group({
      name: {
        value: this.system.name,
        disabled: false
      },
      notes: {
        value: this.system.notes,
        disabled: false
      },
      isMotorised: { value: this.system.isMotorised, disabled: false },
      isOurs: { value: this.system.isOurs, disabled: false }
    });
  }

  onClickDelete() {
    if (confirm('Are you sure you want to delete the system?')) {
      this.deleting = true;

      const request: DeleteSystemRequest = {
        siteId: this.siteSummary.site.id,
        systemId: this.system.id
      };

      this.http.post(ApiEndpoint.site.deleteSystem, request).subscribe(
        res => {
          this.toastr.success('System deleted');
          this.modal.close();
        },
        err => {
          this.toastr.error(err.message, 'Error deleting system');
          this.deleting = false;
        }
      );
    }
  }

  onSubmit() {
    this.saving = true;

    const saveRequest: UpdateSystemRequest = {
      siteId: this.siteSummary.site.id,
      system: { ...this.system }
    };

    saveRequest.system.name = this.form.get('name').value;
    saveRequest.system.notes = this.form.get('notes').value;
    saveRequest.system.isMotorised = this.form.get('isMotorised').value;
    saveRequest.system.isOurs = this.form.get('isOurs').value;

    if (!this.system) {
      saveRequest.system.id = -1;
    }

    this.http.post(ApiEndpoint.site.updateSystem, saveRequest).subscribe(
      res => {
        if (this.system) {
          this.toastr.success('System updated');
        } else {
          this.toastr.success('System created');
        }
        this.modal.close();
      },
      err => {
        this.toastr.error(err.message, 'Update System Error');
        this.saving = false;
      }
    );
  }
}
