<div *ngIf="job.status === jobStatuses.Deleted" class="alert alert-danger text-center" role="alert">
  You cannot edit a deleted job.
</div>
<div *ngIf="job.status === jobStatuses.Legacy" class="alert alert-danger text-center" role="alert">
  You cannot edit a legacy job.
</div>
<div *ngIf="job.status === jobStatuses.Complete" class="alert alert-danger text-center" role="alert">
  You cannot edit a completed job.
</div>
<div *ngIf="job.status === jobStatuses.Cancelled" class="alert alert-danger text-center" role="alert">
  You cannot edit a cancelled job.
</div>
<div *ngIf="showUpdatedWarning" class="alert alert-warning text-center" role="alert">
  This job has been modified and the information is out of date.<br />You must
  re-open this form to carry out any changes.
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="container">
    <div class="row">
      <div class="col font-weight-bold text-center site-summary-header" *ngIf="job.id !== 0">
        Editing job - {{ job.id }}
      </div>
      <div class="col font-weight-bold text-center site-summary-header" *ngIf="job.id === 0">
        Create new job
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col">
            <div class="font-weight-bold text-center site-summary-header">
              Status
            </div>
            <select class="form-control" formControlName="status">
              <option [value]="jobStatuses.Pending">Pending</option>
              <option [value]="jobStatuses.Complete">Complete</option>
              <option [value]="jobStatuses.Deleted">Deleted</option>
              <option [value]="jobStatuses.Cancelled">Cancelled</option>
              <option [value]="jobStatuses.Legacy">Legacy</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col">&nbsp;</div>
        </div>

        <div class="row">
          <div class="col">
            <div class="font-weight-bold text-center site-summary-header">
              Due Date
            </div>
            <input class="form-control" type="date" formControlName="dueDate" />
          </div>
        </div>

        <div class="row">
          <div class="col">&nbsp;</div>
        </div>

        <div class="row">
          <div class="col">
            <div class="font-weight-bold text-center site-summary-header" *ngIf="job.status === jobStatuses.Complete">
              Completion time
            </div>
            <input class="form-control" type="text" formControlName="timeCompleted"
              *ngIf="job.status === jobStatuses.Complete" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          Description
        </div>
        <textarea class="form-control" formControlName="description" rows="5"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col">&nbsp;</div>
    </div>

    <div class="row" *ngIf="job.entries.length === 0">
      <div class="col font-weight-bold text-center site-summary-header">
        Create from template
      </div>
    </div>
    <div class="row" *ngIf="job.entries.length === 0">
      <div class="col-12 text-center">
        <button class="btn btn-primary" type="button" (click)="createTemplateService()">
          Service all systems
        </button>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-primary" type="button" (click)="createTemplateService(true)">
          Service all systems with weld integrity test
        </button>
      </div>
    </div>
  </div>

  <div class="container" formArrayName="entries" *ngFor="let item of getEntriesArray().controls; let i = index">
    <div class="row">
      <div class="col">&nbsp;</div>
    </div>

    <div class="row" [formGroupName]="i">
      <div class="col-1 site-summary-header">&nbsp;</div>
      <div class="col-10 font-weight-bold text-center site-summary-header">
        Job entry {{ i + 1 }}
      </div>
      <div class="col-1 text-right site-summary-header">
        <i class="fas fa-arrow-up pointer" (click)="moveJobEntryUp(i)" [hidden]="i === 0"></i>
        <i class="fas fa-arrow-down pointer" (click)="moveJobEntryDown(i)" [hidden]="i === job.entries.length - 1"></i>
      </div>
    </div>
    <div class="row" [formGroupName]="i">
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          System
        </div>
        <select class="form-control" formControlName="systemId">
          <option *ngFor="let system of site.systems" [value]="system.id">{{
            system.name
          }}</option>
          <option value="0">None</option>
        </select>
      </div>

      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          Visit Type
        </div>
        <select class="form-control" formControlName="type">
          <option [value]="jobTypes.Unknown">Unknown</option>
          <option [value]="jobTypes.Installation">Installation</option>
          <option [value]="jobTypes.ReplacementCover">Replacement Cover</option>
          <option [value]="jobTypes.Delivery">Delivery</option>
          <option [value]="jobTypes.Service">Service</option>
          <option [value]="jobTypes.Repair">Repair</option>
          <option [value]="jobTypes.Survey">Survey</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          Options
        </div>
        <div *ngFor="let jobOption of getJobOptions(i)">
          {{ getJobOptionText(jobOption) }}
          <input type="checkbox" [checked]="jobOptionSelected(i, jobOption)"
            (change)="jobOptionChange($event, i, jobOption)" />
        </div>
      </div>
    </div>
    <div class="row" [formGroupName]="i">
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          Description <i class="fas fa-info-circle"
            ngbTooltip="Public description of the work, included on generated paperwork"></i>
        </div>
        <textarea class="form-control" formControlName="description"></textarea>
      </div>
    </div>
    <div class="row" [formGroupName]="i">
      <div class="col">
        <div class="font-weight-bold text-center site-summary-header">
          Private Notes
          <i class="fas fa-info-circle" ngbTooltip="Private notes, only visible on internal systems"></i>
        </div>
        <textarea class="form-control" formControlName="notes"></textarea>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">&nbsp;</div>
  </div>

  <div class="row">
    <div class="col text-left">
      <button type="button" class="btn btn-primary" [disabled]="disabled || saving" (click)="addJobEntry()">
        Add Job Entry
      </button>
    </div>
    <div class="col text-right">
      <button class="btn btn-success" [disabled]="disabled || saving">
        Save
      </button>
    </div>
  </div>
</form>