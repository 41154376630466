import { ToastrService } from "ngx-toastr";
import { User } from "./../../viewmodels/generated/user";
import { JobState } from "./../../viewmodels/generated/job-state";
import { JobService } from "./../../service/job.service";
import { JobSearchResponse } from "src/app/viewmodels/generated/job-search-response";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { isNumber } from "util";

@Component({
  selector: "app-select-job",
  templateUrl: "./select-job.component.html",
  styleUrls: ["./select-job.component.scss"],
})
export class SelectJobComponent implements OnInit, OnDestroy {
  @Input("unassignedJobs") unassignedJobs: JobSearchResponse[];
  @Input("selectedJob") selectedJob: JobState;
  @Input("engineers") engineers: User[];
  @Input("start") start: Date;
  @Input("end") end: Date;
  @Input("editingExisting") editingExisting: boolean;

  searchTerms: string;
  searchTextControl = new FormControl();
  searchText$ = new Subscription();
  searchTextLast: string;
  searchResults: JobSearchResponse[] = [];

  primaryEngineer: User;
  selectedEngineers: User[] = [];

  constructor(
    private modal: NgbActiveModal,
    private jobService: JobService,
    private toasr: ToastrService
  ) {}

  ngOnInit() {
    this.searchResults = this.unassignedJobs;

    this.searchText$ = this.searchTextControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((val) => {
        this.onTextSearchUpdate(val);
      });

    if (this.selectedJob) {
      this.selectedEngineers = this.selectedJob.assignedUsersList.map(
        (assigned) => this.engineers.find((e) => e.id === +assigned)
      );
    }
  }

  ngOnDestroy() {
    if (this.searchText$) {
      this.searchText$.unsubscribe();
    }
  }

  onTextSearchUpdate(val: string) {
    this.searchTextLast = val;

    if (!val || val.length === 0) {
      this.searchResults = this.unassignedJobs;
    }

    this.searchResults = this.unassignedJobs.filter((res) => {
      const escapedVal = val.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
      const regexp = new RegExp(escapedVal, "gmi");

      if (regexp.test(this.jobService.getFormattedDescription(res.job))) {
        return true;
      }
      if (regexp.test(res.job.id.toString())) {
        return true;
      }
      if (regexp.test(res.site.id.toString())) {
        return true;
      }
      if (regexp.test(res.site.fullAddress)) {
        return true;
      }
      return false;
    });
  }

  highlightRegexSearch(val) {
    if (isNumber(val)) {
      val = val.toString();
    }

    if (!this.searchTextLast || this.searchTextLast.length === 0) {
      return this.jobService.nl2br(val);
    }

    const escapedVal = this.searchTextLast.replace(
      /[\-\[\]{}()*+?.,\\\^$|#\s]/g,
      "\\$&"
    );
    const regexp = new RegExp(`(${escapedVal})`, "gmi");
    return this.jobService.nl2br(val.replace(regexp, "<b>$1</b>"));
  }

  selectJob(job: JobState) {
    this.selectedJob = job;
  }

  onEngineerChanged(user: User, event) {
    const newVal = event.currentTarget.checked;

    if (newVal) {
      this.addEngineer(user);
    } else {
      this.removeEngineer(user);
    }
  }

  onPrimaryEngineerChanged(user: User, event) {
    const newVal = event.currentTarget.checked;

    if (newVal) {
      this.setPrimaryEngineer(user);
    } else {
      this.removePrimaryEngineer(user);
    }
  }

  isPrimaryEngineer(user: User) {
    if (
      this.selectedEngineers.length === 0 ||
      this.selectedEngineers[0].id !== user.id
    ) {
      return false;
    }
    return true;
  }

  isSelectedEngineer(user: User) {
    if (this.selectedEngineers.length === 0) {
      return false;
    }
    return this.selectedEngineers.find((a) => a.id == user.id);
  }

  setPrimaryEngineer(user: User) {
    // Primary engineer is the first engineer
    const others = this.selectedEngineers.filter((a) => a.id !== user.id);
    this.selectedEngineers = [user, ...others];
  }

  removePrimaryEngineer(user: User) {
    if (!this.selectedEngineers.find((a) => a.id === user.id)) {
      return;
    }
    // Primary engineer is the first engineer
    const others = this.selectedEngineers.filter((a) => a.id !== user.id);
    this.selectedEngineers = [...others, user];
  }

  addEngineer(user: User) {
    if (this.selectedEngineers.find((a) => a.id === user.id)) {
      return;
    }
    this.selectedEngineers.push(user);
  }

  removeEngineer(user: User) {
    this.selectedEngineers.filter((a) => a.id !== user.id);
  }

  deleteJobAssignment() {
    this.selectedEngineers = [];
    this.selectedJob.assignedTimeEnd = null;
    this.selectedJob.assignedTimeStart = null;

    this.jobService.update(this.selectedJob).subscribe(
      (res) => {
        this.modal.close(true);
        this.toasr.success("Unassigned job");
      },
      (err) => {
        this.modal.close(false);
        this.toasr.error("Error unassigning job, see console log for details");
        console.log(err);
      }
    );
  }

  saveResults() {
    const job = this.selectedJob;
    job.assignedUsersList = this.selectedEngineers.map((a) => a.id);
    job.assignedTimeStart = this.start;
    job.assignedTimeEnd = this.end;
    this.jobService.update(job).subscribe(
      (res) => {
        this.modal.close(true);
        this.toasr.success("Assigned job");
      },
      (err) => {
        this.modal.close(false);
        this.toasr.error("Error assigning job, see console log for details");
        console.log(err);
      }
    );
  }
}
