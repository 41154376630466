import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ApiEndpoint } from 'src/app/enum/apiendpoints.enum';
import { SignalRService } from 'src/app/signal-r.service';
import { ConfigEntry } from 'src/app/viewmodels/generated/config-entry';
import { EditConfigComponent } from '../edit-config/edit-config.component';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.sass']
})
export class ConfigComponent implements OnInit, OnDestroy {
  configurations: ConfigEntry[];
  configEntryUpdated$: Subscription;

  constructor(private http: HttpClient,
    private modalService: NgbModal,
    private signalr: SignalRService) { }

  ngOnInit(): void {
    this.getConfig();

    this.configEntryUpdated$ = this.signalr.configEntryUpdatedObserver.subscribe((id) => {
      this.getConfig();
    });
  }

  ngOnDestroy(): void {
    if (this.configEntryUpdated$) {
      this.configEntryUpdated$.unsubscribe();
    }
  }

  getConfig() {
    this.http.get<ConfigEntry[]>(ApiEndpoint.config.getall).subscribe(res => {
      this.configurations = res;
    });
  }

  addSetting() {
    const modalRef = this.modalService.open(EditConfigComponent, {
      size: "lg",
    });
    modalRef.componentInstance.configEntry = null;
  }

  editConfig(config) {
    const modalRef = this.modalService.open(EditConfigComponent, {
      size: "lg",
    });
    modalRef.componentInstance.configEntry = config;
  }
}
