import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiEndpoint } from 'src/app/enum/apiendpoints.enum';
import { SignalRService } from 'src/app/signal-r.service';
import { ConfigEntry } from 'src/app/viewmodels/generated/config-entry';
import { ConfigType } from 'src/app/viewmodels/generated/config-type';

@Component({
  selector: 'app-edit-config',
  templateUrl: './edit-config.component.html',
  styleUrls: ['./edit-config.component.sass']
})
export class EditConfigComponent implements OnInit, OnDestroy {
  @Input() configEntry: ConfigEntry;

  updated = false;
  form: FormGroup;
  configEntryUpdated$: any;

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private modal: NgbActiveModal,
    private signalr: SignalRService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: [null],
      type: [ConfigType.StringArray],
      value: [null],
      values: this.formBuilder.array([])
    });

    if (this.configEntry) {
      this.form.get('id').disable();
      this.form.patchValue(this.configEntry);

      if (!this.isSingleValueSetting()) {
        const formArray = this.getValuesArray();
        const values = JSON.parse(this.configEntry.value);

        values.forEach(val => {
          formArray.push(this.formBuilder.control(val));
        });
      }
    }

    this.configEntryUpdated$ = this.signalr.configEntryUpdatedObserver.subscribe((id) => {
      if (this.configEntry && this.configEntry.id === id) {
        this.updated = true;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.configEntryUpdated$) {
      this.configEntryUpdated$.unsubscribe();
    }
  }

  addValue() {
    const array = this.form.get('values') as FormArray
    array.push(this.formBuilder.control(null));
  }

  getValuesArray() {
    return this.form.get('values') as FormArray;
  }

  isSingleValueSetting() {
    return false;
  }

  removeValue(index) {
    const valueArray = this.getValuesArray();
    valueArray.removeAt(index);
  }

  onSubmit() {
    const updatedEntity = { ...this.configEntry };

    updatedEntity.id = this.form.get('id').value;
    updatedEntity.type = this.form.get('type').value;

    if (this.isSingleValueSetting()) {
      updatedEntity.value = this.form.get('value').value;
    }
    else {
      updatedEntity.value = JSON.stringify([...this.form.get('values').value]);
    }

    this.http.put(ApiEndpoint.config.put(updatedEntity.id), updatedEntity).subscribe(
      () => {
        this.toastr.success('Config updated');
        this.modal.close();
      },
      () => {
        this.toastr.error('Error updating config');
      }
    );
  }
}
